import { Route, Redirect, RouteProps } from "react-router-dom";

type Props = {
  condition: boolean;
  redirectTo: string;
} & RouteProps;

const GuardedRoute = ({ condition, redirectTo, render, ...props }: Props) => (
  <Route
    {...props}
    render={(props) =>
      condition ? <>{render?.(props)}</> : <Redirect to={redirectTo} />
    }
  />
);

export default GuardedRoute;
