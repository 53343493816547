import { Button } from "@gle/base-ui.buttons.button";
import { Heading } from "@gle/base-ui.typography.heading";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { ViewProps } from ".";
import { PersonalDataForm } from "../../../components/Forms";
import { Enroll } from "../../../redux/Enroll/types";
import { AppState } from "../../../redux/types";

export const Personal = (props: ViewProps<Enroll["data"]["personal"]>) => {
  const { messages } = useSelector((state: AppState) => state.Locale);

  const formRef: any = useRef();

  return (
    <>
      <Heading level={3} className="mb-20">
        Inserisci i dati dell'atleta
      </Heading>

      <PersonalDataForm
        athlete={true}
        formRef={formRef}
        initialValues={props.data}
        onSubmit={(values) => props.onSubmit?.(values)}
      />

      <Button
        type="submit"
        onClick={() => formRef.current.submitForm()}
        className="block mt-20"
      >
        {messages["next"]}
      </Button>
    </>
  );
};
