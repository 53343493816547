import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { FormProps } from ".";
import { Enroll } from "../../redux/Enroll/types";
import { AppState } from "../../redux/types";
import { InputField } from "../Inputs/Input";
import { SelectField } from "../Inputs/Select";
import { FieldLabel } from "../field-label";
import { addressSchema } from "./schemas";

const Component = ({ ...props }: FormProps<Enroll["data"]["address"]>) => {
  const { countries, regions, provinces } = useSelector(
    (state: AppState) => state.Locations
  );

  const { messages } = useSelector((state: AppState) => state.Locale);

  const onSubmit = (values: any) => {
    props.onSubmit(values);
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={addressSchema(messages, true)}
      initialValues={props.initialValues}
      validateOnMount={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      innerRef={props.formRef}
    >
      {(formContext) => {
        return (
          <Form>
            <div className="flex gap-20 flex-column">
              <div className="flex flex-row gap-20">
                <InputField
                  name="address"
                  label={
                    <FieldLabel
                      config={{ label: messages["address"], required: true }}
                    />
                  }
                  style={{ flex: 0.75 }}
                />

                <InputField
                  name="civic_number"
                  label={
                    <FieldLabel
                      config={{
                        label: messages["civic_number"],
                      }}
                    />
                  }
                  style={{ flex: 0.25 }}
                />
              </div>

              <div className="flex flex-row gap-20">
                <InputField
                  name="town"
                  label={
                    <FieldLabel
                      config={{
                        label: messages["town"],
                        required: true,
                      }}
                    />
                  }
                  style={{ flex: 0.75 }}
                />
                <InputField
                  name="cap"
                  label={
                    <FieldLabel
                      config={{
                        label: messages["cap"],
                        required: true,
                      }}
                    />
                  }
                  style={{ flex: 0.25 }}
                  inputMode="numeric"
                />
              </div>
              <SelectField
                name="nation"
                label={
                  <FieldLabel
                    config={{
                      label: messages["nation"],
                      required: true,
                    }}
                  />
                }
                onChange={() => {
                  formContext.setFieldValue("region", undefined);
                  formContext.setFieldValue("province", undefined);
                }}
                options={Object.keys(countries).map((key) => ({
                  value: key,
                  label: countries[key].label,
                }))}
              />
              {formContext.values.nation === "ITA" && (
                <>
                  <SelectField
                    name="province"
                    label={
                      <FieldLabel
                        config={{
                          label: messages["province"],
                          required:
                            formContext.values.nation === "ITA" ? true : false,
                        }}
                      />
                    }
                    isClearable
                    options={Object.keys(provinces).map((key) => ({
                      value: key,
                      label: provinces[key].label,
                    }))}
                  />

                  <SelectField
                    name="region"
                    label={
                      <FieldLabel
                        config={{
                          label: messages["region"],
                          required:
                            formContext.values.nation === "ITA" ? true : false,
                        }}
                      />
                    }
                    isClearable
                    options={Object.keys(regions).map((key) => ({
                      value: key,
                      label: regions[key].label,
                    }))}
                  />
                </>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Component;
