import { Button, ButtonProps } from "@gle/base-ui.buttons.button";
import { Text } from "@gle/base-ui.typography.text";
import { IconTrash, IconUpload } from "@golee/gle-icons";
import axios from "axios";
import React, { ChangeEvent, useRef, useState } from "react";

type UploadedFile = {
  _id: string;
  url: string;
  name: string;
  mimeType: string;
};

type FileUploaderProps = {
  id?: string;
  api: {
    baseURL: string;
  };
  onValueChanged?: (value?: UploadedFile) => void;
  onUploadStarted?: (file: File) => void;
  onStatusChanged?: (status: "uploading" | "uploaded") => void;
  onUploadCompleted?: (file: UploadedFile, name: string) => void;
  onError?: (err?: any) => void;
  value?: string;
  allowDelete?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  buttonLabel?: string;
  buttonType?: ButtonProps["level"];
  buttonSize?: ButtonProps["size"];
  buttonColor?: ButtonProps["color"];
  hideDocumentLabel?: boolean;
  mimeTypes?: string;
};

export const FileUploader = ({
  value,
  style = {},
  buttonType = "secondary",
  buttonSize = "s",
  buttonColor = "primary",
  hideDocumentLabel,
  mimeTypes = "application/pdf",
  ...props
}: FileUploaderProps) => {
  const inputRef = useRef<any>();
  const [isUploading, setIsUploading] = useState(false);

  const uploadFile = async (file: File) => {
    let formData = new FormData();
    formData.append("file", file);

    props.onUploadStarted?.(file);
    props.onStatusChanged?.("uploading");

    setIsUploading(true);

    try {
      const response = await axios.post(props.api.baseURL, formData);

      const url = response.data.magic_url || response.data.public_url;

      props.onValueChanged?.({
        _id: response.data._id,
        name: file.name,
        mimeType: response.data.mime,
        url,
      });

      props.onStatusChanged?.("uploaded");

      props.onUploadCompleted?.(
        {
          _id: response.data._id,
          name: file.name,
          mimeType: response.data.mime,
          url,
        },
        file.name
      );

      return false;
    } catch (err) {
      props.onStatusChanged?.("uploaded");
      props.onError?.(err);

      return false;
    } finally {
      setIsUploading(false);
      inputRef.current.value = "";
    }
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) return;

    const file = event.target.files[0];

    uploadFile(file);
  };

  return (
    <div className={"file-uploader"} style={{ display: "flex", gap: 5 }}>
      {!!value && (
        <div>
          <FileName
            value={value}
            onDelete={
              props.allowDelete
                ? () => {
                    props.onValueChanged?.();
                  }
                : undefined
            }
          />
        </div>
      )}

      <div>
        <input
          type="file"
          accept={mimeTypes}
          hidden
          ref={inputRef}
          onChange={handleFileUpload}
        />

        <Button
          size="s"
          style={{ position: "relative" }}
          color="ghost"
          className="auto-height"
          level="secondary"
          isLoading={isUploading}
          onClick={() => inputRef.current.click()}
        >
          <IconUpload />

          {props.buttonLabel ? (
            props.buttonLabel
          ) : (
            <>{`${value ? "" : "Carica"}`}</>
          )}
        </Button>
      </div>
    </div>
  );
};

const FileName = ({
  value,
  ...props
}: {
  value: string;
  onDelete?: () => void;
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Text
        size="s"
        style={{ display: "flex", gap: 2, alignItems: "center" }}
        color="gray"
      >
        <a
          href={value}
          target={"_blank"}
          style={{ color: "var(--gray-color)", marginRight: 5 }}
        >
          CARICATO
        </a>
        <span
          style={{
            display: "inline-block",
            height: 15,
            width: 15,
            borderRadius: "50%",
            backgroundColor: "var(--success-color)",
          }}
        ></span>
      </Text>

      {props.onDelete && (
        <Button
          size="s"
          className="auto-height"
          level="secondary"
          onClick={props.onDelete}
          color="danger"
          style={{ marginLeft: 10 }}
        >
          <IconTrash />
        </Button>
      )}
    </div>
  );
};
