import axiosConan from './interceptor';
import api from './api';

export const trackEvent = async (payload: any): Promise<void> => {
	try {
		await axiosConan.post(api.trackEvent(), payload);
		return;
	} catch (err) {
		console.log(err);
	}
};

export const enrichUserProperties = async (payload: any): Promise<void> => {
	try {
		await axiosConan.post(api.enrichUserProperties(), payload);
		return;
	} catch (err) {
		console.log(err);
	}
};
