import { Button } from "@gle/base-ui.buttons.button";
import { Heading } from "@gle/base-ui.typography.heading";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { ViewProps } from ".";
import { AddressForm } from "../../../components/Forms";
import { Enroll } from "../../../redux/Enroll/types";
import { AppState } from "../../../redux/types";
import { Text } from "@gle/base-ui.typography.text";

export const Address = (
  props: ViewProps<Enroll["data"]["address"]> & {
    parent?: Enroll["data"]["parent"];
  }
) => {
  const { messages } = useSelector((state: AppState) => state.Locale);

  const formRef: any = useRef();

  const copyAddressFromParent = () => {
    if (props.parent && props.parent.address) {
      formRef.current.setValues(props.parent.address);
    }
  };

  const renderSameAsParentButton = () => {
    if (!props.parent || !props.parent.address) {
      return false;
    }

    return !!Object.keys(props.parent.address).filter(
      (t) => (props.parent!.address as any)[t]
    ).length;
  };

  return (
    <>
      <Heading level={3} className="mb-20">
        Indirizzo atleta
        <Text
          weight={"light"}
          color="gray-light"
          style={{ display: "inline-block", marginLeft: 5 }}
        ></Text>
      </Heading>

      {renderSameAsParentButton() && (
        <Text
          onClick={copyAddressFromParent}
          color="accent"
          size="s"
          style={{
            cursor: "pointer",
            marginBottom: 10,
          }}
        >
          Copia dati da genitore / tutore legale
        </Text>
      )}

      <AddressForm
        formRef={formRef}
        initialValues={props.data}
        onSubmit={(values) => props.onSubmit?.(values)}
      />

      <div className="flex gap-20 mt-20">
        <Button
          level="tertiary"
          color="accent"
          onClick={props.onBack}
          style={{ flex: "0.2" }}
        >
          {messages["back"]}
        </Button>
        <Button
          type="submit"
          onClick={() => formRef.current.submitForm()}
          style={{ flex: "0.8" }}
        >
          {messages["next"]}
        </Button>
      </div>
    </>
  );
};
