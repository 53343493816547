import axios from 'axios';

const apiUrl = window._env_.REACT_APP_API_URL_PEOPLE_SERVICE;

const instance = axios.create({
	baseURL: apiUrl,
	headers: {
		'Content-Type': 'application/json',
	},
});

instance.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		return Promise.reject(error.response ? error.response : error);
	}
);

export default instance;
