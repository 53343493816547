import { ErrorMessage, FieldConfig, useField } from "formik";
import { useEffect } from "react";
import styled from "styled-components";
import Component, { TextProps } from "./Component";

type Props = FieldConfig<any> & { touched?: boolean } & Pick<
    TextProps,
    | "label"
    | "placeholder"
    | "type"
    | "min"
    | "max"
    | "upperCase"
    | "lowerCase"
    | "pascalCase"
    | "inputMode"
    | "style"
  >;

const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  column-gap: 3px;
  row-gap: 3px;
  gap: 3px;

  &.h-auto {
    height: auto;
  }

  .form-field-error {
    color: var(--danger-color);
    font-size: var(--xs-size);
  }

  &.error {
    label {
      color: var(--danger-color);
    }
    input {
      border-color: var(--danger-color);
    }
  }
`;

const Field = ({ style = {}, ...props }: Props) => {
  const [field, meta, helpers] = useField(props);

  useEffect(() => {
    helpers.setTouched(true, true);
  }, [props.touched]);

  return (
    <Wrapper
      style={style}
      className={`${meta.error && meta.touched ? "error" : ""} ${
        props.type === "textarea" ? "h-auto" : ""
      }`}
    >
      <Component {...props} {...field} onChange={helpers.setValue} />
      <ErrorMessage
        name={props.name}
        component="div"
        className="form-field-error"
      />
    </Wrapper>
  );
};

export default Field;
