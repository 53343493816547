import { Loader } from "@gle/base-ui.loader";
import { Text } from "@gle/base-ui.typography.text";
import { IconTrash, IconUpload } from "@golee/gle-icons";
import axios from "axios";
import { ErrorMessage } from "formik";
import React, { ReactElement, memo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { AppState } from "../../redux/types";
import Toast from "../Toast";

export type ImageUploaderProps = {
  name?: string | string[];
  label?: ReactElement;
  value?: string;
  onChange: (url?: string) => void;
};

const Style = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  .corner {
    position: relative;
    border: 1px dashed var(--gray-lighter-color);
    border-radius: 8px;
    height: 120px;
    width: 120px;
    background-color: var(--gray-extra-lightest-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .empty-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    .remove-photo {
      border: 1px dashed var(--gray-lighter-color);
      border-radius: 50%;
      background-color: var(--gray-extra-lightest-color);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--gray-color);
      top: -12px;
      right: -12px;
      width: 24px;
      height: 24px;
      text-align: center;
      font-size: 16px;
      color: var(--danger-color);

      :hover {
        cursor: pointer;
        border-color: var(--gray-color);
      }
    }

    img {
      max-height: 80%;
      max-width: 80%;
    }
  }
`;

const uploadFile = async (fileObj: File): Promise<{ public_url: string }> => {
  const postUrl = `${window._env_.REACT_APP_API_URL_PEPA_SERVICE}files`;

  if ("image/jpeg, image/png".includes(fileObj.type)) {
    const config = {
      onUploadProgress: function (progressEvent: any) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      },
    };

    let formData = new FormData();
    formData.append("file", fileObj);
    const response = await axios.post(postUrl, formData, config);

    return response.data;
  } else {
    throw new Error(`${fileObj.type} non supportato`);
  }
};

const ImageUploader = (props: ImageUploaderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { messages } = useSelector((state: AppState) => state.Locale);

  const inputFile = useRef<HTMLInputElement | null>(null);

  const onSelectFile = async (e?: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);

    try {
      if (e?.target.files && e.target.files.length > 0) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        try {
          const { public_url } = await uploadFile(e.target.files[0]);
          props.onChange(public_url);
        } catch (err) {}
      }
    } catch (err) {
      Toast.error("Si è verificato un errore");
    } finally {
      setIsLoading(false);
    }
  };

  const Empty = () => (
    <div className={"empty-wrapper corner"}>
      {isLoading ? (
        <Loader />
      ) : (
        <div style={{ textAlign: "center" }}>
          <IconUpload style={{ width: 16 }} color="var(--gray-color)" />
          <Text size="xs" color="gray">
            {messages["upload_an_image"]}
          </Text>
        </div>
      )}

      <input
        ref={inputFile}
        type="file"
        accept="image/*"
        onChange={onSelectFile}
        style={{ display: "none" }}
      />
    </div>
  );

  const ImageFrame = () => (
    <div
      className={`corner`}
      onClick={() =>
        inputFile.current &&
        inputFile.current.click &&
        inputFile.current.click()
      }
    >
      <div
        className={"remove-photo"}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          props.onChange(undefined);
        }}
      >
        <IconTrash style={{ width: 15 }} />
      </div>

      <img src={props.value} alt={"image"} />

      <input
        ref={inputFile}
        type="file"
        accept="image/*"
        onChange={onSelectFile}
        style={{ display: "none" }}
      />
    </div>
  );

  const Content = () => {
    if (props.value) return <ImageFrame />;
    else
      return (
        <div
          style={{ width: "fit-content" }}
          onClick={() =>
            inputFile.current &&
            inputFile.current.click &&
            inputFile.current.click()
          }
        >
          <Empty />
        </div>
      );
  };

  return (
    <Style>
      {props.label && (
        <Text color={"gray"} style={{ margin: "0 0 10px 0" }} size="s">
          {props.label}
        </Text>
      )}
      <ErrorMessage
        name={[props.name] as unknown as string}
        component="div"
        className="form-field-error"
      />
      <Content />
    </Style>
  );
};

export default memo(ImageUploader);
