import { Loader } from "@gle/base-ui.loader";
import { Theme } from "@gle/base-ui.theme-provider";
import { Heading } from "@gle/base-ui.typography.heading";
import { Text } from "@gle/base-ui.typography.text";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandLogo } from "../components/BrandLogo";
import { ACTIONS } from "../redux/Club/reducer";
import { AppState } from "../redux/types";
import { ClubService, PeopleService } from "../services";
import axios from "./../services/People/interceptor";

const getGroups = async (organizationId: string) => {
  try {
    const { data } = await axios.get<
      { groupId: string; name: string; type: string }[]
    >(`org-people-groups/public/${organizationId}`, { params: { type: null } });
    return data.filter((group) => group.type !== "financial");
  } catch (err) {
    return [];
  }
};

const ClubProvider = ({ ...props }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  const dispatch = useDispatch();

  const club = useSelector((state: AppState) => state.Club);

  const { messages } = useSelector((state: AppState) => state.Locale);

  const loadClub = async (slug: string) => {
    setError(false);
    try {
      const club = await ClubService.getBySlug(slug);
      const groups = await getGroups(club._id);
      const enrollSettings = await PeopleService.getEnrollSettings(club._id);

      dispatch({
        type: ACTIONS.LOAD_CLUB,
        payload: {
          ...club,
          sharableUrl: `${window._env_.PUBLIC_URL}${slug}/athlete`,
          groups,
          enrollSettings,
        },
      });
    } catch (err) {
      setIsLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    const slug = window.location.pathname.split("/")[1];
    loadClub(slug);
  }, []);

  useEffect(() => {
    if (club?._id) {
      setIsLoading(false);
    }
  }, [club]);

  return (
    <Theme>
      {!isLoading && club._id && Object.keys(messages).length ? (
        <>{props.children}</>
      ) : (
        <>
          {!error ? (
            <div className="flex items-center justify-center full-height">
              <Loader size="l" />
            </div>
          ) : (
            <NotFoundPage />
          )}
        </>
      )}
    </Theme>
  );
};

const NotFoundPage = () => {
  return (
    <div
      className="flex items-center justify-center full-height"
      style={{ flexDirection: "column" }}
    >
      <Heading level={3} style={{ marginBottom: 10 }}>
        Oops! Questo link non esiste più!
      </Heading>
      <Text>
        Questo link non è più attivo. Richiedi alla tua società di condividerti
        il link di iscrizione corretto!
      </Text>

      <div style={{ marginTop: 100 }}>
        <BrandLogo color="red" />
      </div>
    </div>
  );
};

export default ClubProvider;
