import { Button } from "@gle/base-ui.buttons.button";
import { Heading } from "@gle/base-ui.typography.heading";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { ViewProps } from ".";
import { ExtraDataForm } from "../../../components/Forms";
import { Enroll } from "../../../redux/Enroll/types";
import { AppState } from "../../../redux/types";
import { Text } from "@gle/base-ui.typography.text";

export const Extra = (props: ViewProps<Enroll["data"]["extra"]>) => {
  const { messages } = useSelector((state: AppState) => state.Locale);

  const formRef: any = useRef();

  return (
    <>
      <Heading level={3} className="mb-20">
        Completa il profilo
        <Text
          weight={"light"}
          color="gray-light"
          style={{ display: "inline-block", marginLeft: 5 }}
        ></Text>
      </Heading>

      <ExtraDataForm
        formRef={formRef}
        initialValues={props.data}
        onSubmit={(values) => props.onSubmit?.(values)}
      />

      <div className="flex gap-20 mt-20">
        <Button
          level="tertiary"
          color="accent"
          onClick={props.onBack}
          style={{ flex: "0.2" }}
        >
          {messages["back"]}
        </Button>
        <Button
          type="submit"
          onClick={() => formRef.current.submitForm()}
          style={{ flex: "0.8" }}
        >
          {messages["next"]}
        </Button>
      </div>
    </>
  );
};
