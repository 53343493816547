import { Heading } from "@gle/base-ui.typography.heading";
import { Text } from "@gle/base-ui.typography.text";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { AppState } from "../../redux/types";
import { BrandLogo } from "../BrandLogo";

const Wrapper = styled.div`
  @media screen and (min-width: 769px) {
    display: flex;
  }
  .desktop {
    background-color: #fffafa;
    border-right: 1px solid #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 35px;
    width: 100%;

    .header {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      gap: 10px;
    }
  }

  .mobile {
    display: none;
  }

  .logo-wrap {
    width: 50px;
    height: 50px;
    background: var(--text-on-primary-color);
    border-radius: 50%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 90%;
    }
  }

  @media screen and (max-width: 768px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
      .toolbar {
        padding: 10px 15px 10px;
        border-bottom: 1px solid #eee;

        .container {
          display: flex;
          align-items: center;
          row-gap: 10px;
          gap: 10px;

          .logo-wrap {
            width: 50px;
            height: 50px;
            padding: 5px;
            margin: 0;

            img {
              width: 90%;
            }
          }
        }
      }

      .info-text {
        margin: 15px 15px 0;
        padding: 10px 25px 10px 10px;
        background-color: var(--accent-lightest-color);
        border-radius: 5px;
        position: relative;

        i {
          position: absolute;
          top: 0px;
          padding: 5px;
          right: 0px;
          cursor: pointer;
          color: var(--accent-dark-color);
        }
      }
    }
  }
`;

const Component = () => {
  const club = useSelector((state: AppState) => state.Club);

  const { enrollSettings } = useSelector((state: AppState) => state.Club);

  return (
    <Wrapper>
      <div className="desktop">
        <div className="top">
          <div className="header">
            <div className="logo-wrap">
              <img
                src={
                  club.logo_url
                    ? club.logo_url
                    : "https://storage.googleapis.com/gle-assets/no-logo.png"
                }
                alt={club.name}
              />
            </div>
            <Heading level={3}>{club.name}</Heading>
          </div>

          <div
            style={{
              paddingRight: 30,
              marginRight: -35,
              paddingBottom: 20,
              maxHeight: 500,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Text
              dangerouslySetInnerHTML={{
                __html: enrollSettings.enroll.description.replace(/\n/g, "<br />"),
              }}
            ></Text>
          </div>
        </div>

        <BrandLogo color="red" width={90} />
      </div>

      <div className="mobile">
        <div className="toolbar">
          <div className="container">
            <div className="logo-wrap">
              <img
                style={{
                  width: club.logo_url ? "90%" : 30,
                }}
                src={
                  club.logo_url
                    ? club.logo_url
                    : "https://storage.googleapis.com/gle-assets/no-logo.png"
                }
                alt={club.name}
              />
            </div>
            <Heading level={4}>{club.name}</Heading>
          </div>

          <div className="container">
            <Text
              style={{ marginTop: 10 }}
              dangerouslySetInnerHTML={{
                __html: enrollSettings.enroll.description.replace(/\n/g, "<br />"),
              }}
            ></Text>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Component;
