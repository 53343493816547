export type Enroll = {
  type: "athlete" | "technical_staff" | "managerial_staff";
  data: {
    personal: {
      name?: string;
      surname?: string;
      fiscal_code?: string;
      privacy?: boolean;
      birthdate?: string;
      birthplace?: string;
      sex?: string;
      recap?: {
        email?: string;
        phone?: string;
      };
    };
    parent: {
      name?: string;
      surname?: string;
      email?: string;
      phone?: string;
      address?: {
        nation?: string;
        region?: string;
        province?: string;
        town?: string;
        address?: string;
        civic_number?: string;
        cap?: string;
      };
      recap?: {
        email?: string;
        phone?: string;
      };
    };
    parent_2: {
      name?: string;
      surname?: string;
      email?: string;
      phone?: string;
      address?: {
        nation?: string;
        region?: string;
        province?: string;
        town?: string;
        address?: string;
        civic_number?: string;
        cap?: string;
      };
      recap?: {
        email?: string;
        phone?: string;
      };
    };
    address: {
      nation?: string;
      region?: string;
      province?: string;
      town?: string;
      address?: string;
      civic_number?: string;
      cap?: string;
    };
    extra: {
      nationality?: string;
      files?: {
        [key: string]: { name: string; url: string; mime: string };
      };
      privacy?: { [key: string]: boolean };
      groups?: string[];
      [key: string]: any;
    };
  };
};

export enum ACTIONS {
  SET_ENROLL_TYPE = "SET_ENROLL_TYPE",
  UPDATE_ENROLL_PERSONAL_DATA = "UPDATE_ENROLL_PERSONAL_DATA",
  UPDATE_ENROLL_EXTRA_DATA = "UPDATE_ENROLL_EXTRA_DATA",
  UPDATE_ENROLL_PARENTS_DATA = "UPDATE_ENROLL_PARENTS_DATA",
  UPDATE_ENROLL_ADDRESS_DATA = "UPDATE_ENROLL_ADDRESS_DATA",
  RESET_ENROLL_DATA = "RESET_ENROLL_DATA",
}

export type EnrollState = Enroll;

export type UpdatePersonalAction = {
  payload: Enroll["data"]["personal"];
  type: ACTIONS.UPDATE_ENROLL_PERSONAL_DATA;
};

export type UpdateExtraAction = {
  payload: Enroll["data"]["extra"];
  type: ACTIONS.UPDATE_ENROLL_EXTRA_DATA;
};

export type UpdateParentsAction = {
  payload: {
    parent: Enroll["data"]["parent"];
    parent_2: Enroll["data"]["parent_2"];
  };
  type: ACTIONS.UPDATE_ENROLL_PARENTS_DATA;
};

export type UpdateAddressAction = {
  payload: Enroll["data"]["address"];
  type: ACTIONS.UPDATE_ENROLL_ADDRESS_DATA;
};

export type SetTypeAction = {
  payload: Enroll["type"];
  type: ACTIONS.SET_ENROLL_TYPE;
};

export type ResetAction = {
  type: ACTIONS.RESET_ENROLL_DATA;
};

export type Actions =
  | UpdatePersonalAction
  | UpdateExtraAction
  | UpdateParentsAction
  | UpdateAddressAction
  | SetTypeAction
  | ResetAction;
