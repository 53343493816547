import axios from "axios";

export const emailValidator = (value?: string, nullable?: boolean) => {

    if (nullable) {
        if (!value) {
            return true;
        }
    }

    if (!value) {
        return false;
    }

    return isValidEmail(value);
};

const isValidEmail = async (value: string): Promise<boolean> => {
    try {
        const { data } = await axios.post<{
            isValid: boolean;
            validatedDomain: boolean;
        }>('https://email-validator.golee.workers.dev/cmd/validate-email-address', { emailAddress: value });
        return data.isValid
    } catch {
        return true
    }
}

