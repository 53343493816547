import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/types";
import axios from "./../../services/Club/interceptor";

export type CustomOption = {
  label: string;
  value: string;
  organizationId?: string;
  isCreatedOption?: boolean;
};

const loadOptions = (key: string, organizationId: string) =>
  axios.get<CustomOption[]>(`custom-options/${key}/${organizationId}/public`, {
    headers: { "x-gle-lang": "it" },
  });

export const CUSTOM_OPTION_BASE_KEY = "select-option";

export const useCustomSelect = (key: string) => {
  const club = useSelector((state: AppState) => state.Club);
  const [options, setOptions] = useState<CustomOption[]>([]);

  const load = async () => {
    try {
      const { data } = await loadOptions(key, club._id);

      setOptions(
        data.map((option) => ({
          label: option.label,
          value: option.value,
          isCreatedOption: !!option.organizationId,
        }))
      );
    } catch (err) {}
  };

  useEffect(() => {
    load();
  }, []);

  return { options };
};
