import PhoneInput from "react-phone-number-input";
import styled from "styled-components";
import "react-phone-number-input/style.css";

const Wrapper = styled.div`
  .PhoneInput {
    margin-top: 0px;
  }

  label {
    font-size: var(--s-size);
    display: block;
    color: var(--gray-light-color);
    margin-bottom: 2px;
  }

  .PhoneInputInput {
    box-sizing: border-box;
    width: 100%;
    padding: 0 7px;
    border: 1px solid transparent;
    border-radius: 10px;
    appearance: none;
    outline: none;
    background: transparent;
    height: 48px;
    font-size: var(--m-size);
    font-family: var(--font-family);
    background-color: #f3f3f4;

    &:focus,
    &:active,
    &:hover {
      border-color: var(--gray-lightest-color);
      background-color: #fff;
    }
  }
`;

export type InputPhoneProps = {
  /**
   * Name of the field.
   */
  name: string;

  /**
   * Value of the field.
   */
  value?: string;

  /**
   * Placeholder of the field.
   */
  placeholder?: string;

  /**
   * Label of the field.
   */
  label: string | React.ReactNode;

  /**
   * On change trigger.
   */
  onChange?: (ev: string) => void;

  /**
   * On blur trigger.
   */
  onBlur?: (ev: any) => void;

  /**
   * On focus trigger.
   */
  onFocus?: (ev: any) => void;
};

const InputPhone = ({ ...props }: InputPhoneProps) => {
  return (
    <Wrapper>
      {props.label && <label htmlFor={props.name}>{props.label}</label>}
      <PhoneInput
        international
        defaultCountry={"IT"}
        placeholder={props.placeholder}
        value={props.value || ""}
        onChange={(val) => props.onChange?.(val as string)}
      />
    </Wrapper>
  );
};

export default InputPhone;
