import { FormConfig } from "../../components/dynamic-form/types";
import { TPublicClub } from "../../services/Club/club.type";

export enum ACTIONS {
  LOAD_CLUB = "LOAD_CLUB",
}

// TODO:
type Group = { groupId: string; name: string };

export type ClubState = TPublicClub & {
  sharableUrl?: string;
  enrollSettings: {
    organizationId: string;
    enroll: {
      description: string;
      formConfig?: FormConfig;
    };
  };
  groups?: Group[];
};

type LoadClubAction = {
  payload: ClubState;
  type: ACTIONS.LOAD_CLUB;
};

const initialState: ClubState | {} = {};

export default function clubReducer(
  state = initialState,
  action: LoadClubAction
): ClubState | {} {
  switch (action.type) {
    case ACTIONS.LOAD_CLUB: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}
