import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Layout from "./Layout";
import { PathUrl } from "./classes/PathUrl/PathUrl.class";
import { Conan } from "./modules/@tracking";
import { history } from "./redux/store";
import AppRouter from "./router";
import axios from "axios";

const App = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    history.listen((location) => {
      scrollToTop();
      const path_url = new PathUrl(location.pathname + location.hash);
      if (!!path_url.area) {
        Conan.trackEvent("Page view", path_url);
      }
    });

    // Redirect to moduli.golee.it
    const slug = window.location.pathname.split('/')[1]
    axios.get(`${window._env_.REACT_APP_API_URL_PEOPLE_SERVICE}legacy-form-redirect/${slug}`).then(res => {
      if(res.data.targetFormFullUrl) window.location.href = res.data.targetFormFullUrl
    })

  }, []);

  return (
    <>
      <Layout>
        <AppRouter history={history} />
      </Layout>
      <ToastContainer />
    </>
  );
};

export default App;
