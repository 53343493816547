export type IconProps = {
  colors?: { 1: string; 2: string; 3: string };
  size?: number;
};

export const AthleteIcon = ({ colors, size = 30 }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 144 164"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.142 106.5C20.4721 105.548 27.5873 103.57 34 102C39.6272 108.948 44.7941 112.825 52.3156 118.827C60.3889 125.89 62 126.405 70.5 131.146C79.7296 126.422 83.3756 123.937 90.6844 117.824C97.8882 111.867 101.897 108.411 108 101.5C114 102.986 118.046 103.277 125.642 106.5C127.919 107.451 129.447 108.368 133.142 111.5C135.703 113.656 136.926 115.204 138.642 118.5C139.99 120.619 140.678 122.113 141.642 126C141.88 128.044 141.724 129.791 141.642 132.5V134L114.5 146.5V164H29V146.5L2.14202 134V132.5C2.00418 129.843 1.90724 128.338 2.14202 126C2.80906 122.95 3.4808 121.377 5.14202 118.5C7.50325 114.56 10.5106 111.567 10.5 111.5C10.4894 111.433 15.8119 107.452 18.142 106.5Z"
      fill={colors?.[1] || "var(--primary-color)"}
    />
    <path
      d="M43 100C46.0932 96.6918 46.8407 93.4459 47 86C56.6246 94.4997 61.8548 96.2404 71 96C80.9984 95.6148 86.2827 93.5998 95 86C95.0012 93.7611 96.101 96.6777 99.5 100C89.2118 109.374 83.0432 114.435 71 123C59.2808 115.353 53.1547 110.4 43 100Z"
      fill="#EDC286"
    />
    <path
      d="M31 28C31.3235 38.1259 32.471 42.4984 34 51C37.3716 41.243 40.9109 37.1844 50 32C68.208 40.9559 79.2641 42.5837 101 37.5L108 51C109.216 46.4678 110.649 43.4831 111 37.5C112.148 24.6451 112.378 17.511 109 5.5C96.0585 5.03473 87.0619 2.8307 72 0C62.0166 0.348737 57.5473 1.87146 52.5 8C37.5243 9.05273 33.2125 13.8411 31 28Z"
      fill="#2D2123"
    />
    <path
      d="M100 36C80.0612 41.3524 69.0361 39.1703 49.5 31C38.7617 35.766 35.6207 39.9492 34 49.5V54.5C30.2263 54.2433 28.9503 55.3508 28 59C28.3739 62.9792 28.9326 65.2553 31 69.5C33.0562 74.0512 34.6507 74.5216 38 73C40.4918 78.9663 42.5331 82.0058 47 87C64.7799 102.03 78.9239 101.21 95 87C99.4453 81.8204 101.177 78.6798 104 73C106.823 74.8189 108.343 73.6182 111 69.5C113.149 65.9845 113.781 63.7171 113.5 59C113.095 55.7267 112.137 54.599 108.5 54.5V49.5L100 36Z"
      fill="#F4D2A3"
    />
    <path
      d="M114.5 164V155L140.5 143V157.5C139.683 160.866 138.789 162.669 134.5 164H114.5Z"
      fill="#EDC286"
    />
    <path
      d="M29 164V155L3 142.5V157.5C3.8167 160.866 4.71129 162.669 9 164H29Z"
      fill="#EDC286"
    />
    <path
      d="M33.5 102L43.0002 100C54.5002 111 59 114.5 70.2654 122.598C80.0002 116 86.0002 111 99.5002 100L108 101.635C95.1286 115.593 87.3293 121.95 70 132.103C53.9177 122.526 46.9145 117.006 33.5 102Z"
      fill={colors?.[2] || "var(--white-color)"}
    />
    <path
      d="M114.5 155V146.5C114.5 146.5 141.417 133.492 141.911 133.082C142.405 132.672 144.362 133.864 143.941 135.121V140.218C143.424 141.381 142.981 141.819 141.911 142.257L114.5 155Z"
      fill={colors?.[2] || "var(--white-color)"}
    />
    <path
      d="M29 155V146.5C29 146.5 2.58252 133.492 2.0888 133.082C1.59509 132.672 -0.362116 133.864 0.058897 135.121V140.218C0.575624 141.381 1.01945 141.819 2.0888 142.257L29 155Z"
      fill={colors?.[2] || "var(--white-color)"}
    />
  </svg>
);
export const TechincalStaffIcon = ({ colors, size = 30 }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 140 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6382 103.542C19.9036 102.616 26.8211 100.693 33.0557 99.1665C37.9488 105.208 50.5933 102.441 58.4413 105.964C61.1402 96.7999 82.1576 119.576 88.1655 114.551C95.1693 108.759 97.5661 105.886 103.5 99.1668C109.333 100.612 114.767 100.408 122.152 103.542C124.366 104.466 125.852 105.357 129.444 108.403C131.934 110.499 133.123 112.004 134.791 115.208C136.101 117.269 136.77 118.721 137.708 122.5C137.939 124.487 137.787 126.185 137.708 128.819V130.278L111.32 142.43V159.444H28.1946V142.43L2.08265 130.278V128.819C1.94865 126.236 1.8544 124.773 2.08265 122.5C2.73117 119.535 3.38425 118.005 4.99932 115.208C7.29496 111.378 10.2188 108.468 10.2085 108.403C10.1982 108.338 15.3728 104.467 17.6382 103.542Z"
      fill={colors?.[1] || "#F8746E"}
    />
    <path
      d="M41.8051 97.2224C44.8124 94.0062 45.5391 90.8504 45.694 83.6113C55.0512 91.8749 60.1361 93.5673 69.0273 93.3336C78.748 92.9591 83.8855 91 92.3607 83.6113C92.3619 91.1568 93.4311 93.9925 96.7357 97.2224C86.7333 106.336 83.4999 113 69.0273 113C56.9999 113 51.6778 107.333 41.8051 97.2224Z"
      fill="#EDC286"
    />
    <path
      d="M97.2216 35.0003C77.8367 40.204 67.1178 38.0825 48.1244 30.1392C37.6844 34.7727 34.6306 38.8398 33.055 48.1253V52.9864C29.3861 52.7368 28.1455 53.8136 27.2216 57.3614C27.5851 61.2301 28.1283 63.4429 30.1383 67.5697C32.1374 71.9945 33.6876 72.4519 36.9438 70.9725C39.3664 76.773 41.351 79.7281 45.6938 84.5836C62.9798 99.1959 76.731 98.3991 92.3605 84.5836C96.6823 79.5479 98.3658 76.4945 101.111 70.9725C103.855 72.7409 105.333 71.5735 107.916 67.5697C110.005 64.1519 110.62 61.9474 110.347 57.3614C109.953 54.179 109.021 53.0826 105.486 52.9864V48.1253L97.2216 35.0003Z"
      fill="#F4D2A3"
    />
    <path
      d="M41.9999 97C38.6175 107.571 57 129 65.4999 135C73.9998 141 90.2978 117.528 96.4999 97"
      stroke="white"
      strokeWidth="0.5"
    />
    <path
      d="M111.319 159.445V150.695L136.597 139.028V153.126C135.803 156.398 134.933 158.151 130.764 159.445H111.319Z"
      fill="#EDC286"
    />
    <path
      d="M28.194 159.444V150.694L2.91625 138.542V153.125C3.71027 156.397 4.58001 158.151 8.74959 159.444H28.194Z"
      fill="#EDC286"
    />
    <path
      d="M111.319 150.695V142.431C111.319 142.431 137.489 129.784 137.969 129.386C138.449 128.987 140.352 130.146 139.943 131.368V136.323C139.44 137.453 139.009 137.88 137.969 138.306L111.319 150.695Z"
      fill={colors?.[2] || "#9F3732"}
    />
    <path
      d="M28.1945 150.695V142.431C28.1945 142.431 2.5108 129.784 2.03079 129.386C1.55079 128.987 -0.352043 130.146 0.0572758 131.368V136.323C0.55965 137.453 0.991146 137.88 2.03079 138.306L28.1945 150.695Z"
      fill={colors?.[2] || "#9F3732"}
    />
    <path
      d="M67.8125 0.171838C42.6781 3.88184 27.5462 10.9238 30.5001 38C61.6151 17.1741 78.6528 17.4643 108.5 37C108.5 8.42326 96.9378 2.83251 70.1982 0.118755C69.4098 0.038741 68.5964 0.0561202 67.8125 0.171838Z"
      fill="#1A3341"
    />
    <g filter="url(#filter0_d_225_344)">
      <path
        d="M107.531 35.946C79.6051 16.142 62.5029 17.0877 31.9944 36.0627C31.0336 36.6603 30.2976 37.5816 29.9259 38.6503L27.3027 46.1921C26.8071 47.6169 27.121 49.1984 28.1232 50.3259C29.4901 51.8636 31.7733 52.1667 33.5196 51.079C62.5684 32.9854 79.341 34.0752 105.866 50.9622C107.605 52.0696 109.926 51.6113 111.069 49.8956C111.661 49.0076 111.846 47.9091 111.577 46.8765L109.452 38.7336C109.16 37.6126 108.476 36.6161 107.531 35.946Z"
        fill="#2C5871"
      />
    </g>
    <path
      d="M31.5899 99.6683L42 96.5C64.022 118.889 75.9111 117.687 96.5 96.5L106.427 99.6585C106.998 99.8402 107.282 100.48 107.034 101.025L93.3055 131.228C92.9686 131.969 91.9268 132.01 91.5068 131.313C78.0302 108.938 64.8348 108.08 46.0381 130.732C45.5629 131.305 44.6379 131.201 44.3307 130.523L30.9702 101.038C30.721 100.488 31.0123 99.8441 31.5899 99.6683Z"
      fill={colors?.[2] || "#9F3732"}
    />
    <circle cx="68.8182" cy="135.818" r="6.81818" fill="#5B5B5B" />
    <path
      d="M70 135.5C70 134.119 71.1193 133 72.5 133C73.8807 133 75 134.119 75 135.5V148.333C75 149.254 74.2538 150 73.3333 150C71.4924 150 70 148.508 70 146.667V135.5Z"
      fill="#5B5B5B"
    />
    <circle cx="68.8182" cy="135.818" r="4.5" fill="#5B5B5B" stroke="white" />
    <defs>
      <filter
        id="filter0_d_225_344"
        x="23.0715"
        y="21.4595"
        width="92.6262"
        height="38.2671"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_225_344"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_225_344"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const ManagerialStaffIcon = ({ colors, size = 30 }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 140 183"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.8053 104.222C44.8126 101.006 45.5393 97.8504 45.6942 90.6113C55.0514 98.8749 60.1363 100.567 69.0275 100.334C78.7481 99.9591 83.8857 98 92.3609 90.6113C92.362 98.1568 93.6953 100.992 96.9999 104.222C86.9975 113.336 80.7362 118.257 69.0275 126.584C57.6339 119.149 51.6779 114.333 41.8053 104.222Z"
      fill="#EDC286"
    />
    <path
      d="M92.3608 15.5C72.9759 20.7037 65.9936 26.9433 47.0002 19C36.5602 23.6336 34.6309 45.8398 33.0553 55.1253V59.9864C29.3864 59.7368 28.1458 60.8136 27.2219 64.3614C27.5855 68.2301 28.1286 70.4429 30.1386 74.5697C32.1377 78.9945 33.6879 79.4519 36.9441 77.9725C39.3667 83.773 41.3513 86.7281 45.6941 91.5836C62.9801 106.196 76.7313 105.399 92.3608 91.5836C96.6826 86.5479 98.3661 83.4945 101.111 77.9725C103.856 79.7409 105.333 78.5735 107.916 74.5697C110.005 71.1519 110.62 68.9474 110.347 64.3614C109.953 61.179 109.022 60.0826 105.486 59.9864V55.1253L92.3608 15.5Z"
      fill="#F4D2A3"
    />
    <path
      d="M111.319 157.695V149.431C111.319 149.431 137.489 136.784 137.969 136.386C138.449 135.987 140.352 137.146 139.943 138.368V143.323C139.44 144.453 139.009 144.88 137.969 145.306L111.319 157.695Z"
      fill="white"
    />
    <path
      d="M28.1945 157.695V149.431C28.1945 149.431 2.5108 136.784 2.03079 136.386C1.55079 135.987 -0.352043 137.146 0.0572758 138.368V143.323C0.55965 144.453 0.991146 144.88 2.03079 145.306L28.1945 157.695Z"
      fill="white"
    />
    <path
      d="M35.9561 71C34.7934 45.2277 33.4479 30.1164 51.1463 22.3012C65.6388 25.2829 73.738 25.773 83.1961 19.8175C84.9463 18.7154 87.21 18.5679 88.8489 19.8295C92.142 22.3646 93.9007 24.875 95.9578 29.0899C96.0876 29.3557 96.1547 29.6522 96.1582 29.9479C96.2671 39.2211 97.9793 44.4193 102.629 52.4224C103.167 53.3483 103.411 54.4199 103.307 55.4856L101.78 71C114.662 38.172 116.97 12.8934 89.4029 10.1264C82.0891 -3.70818 62.3765 -3.03866 40.4569 10.1266C28.6703 20.1542 25.632 27.3388 27.5171 43.3303L35.9561 71Z"
      fill="#2F3A5A"
    />
    <path
      d="M66.5 143.5L56.5 134L69 126.5L81.5 134L73 143.5L79 175.5L71 182.5H67.5L60.5 175.5L66.5 143.5Z"
      fill="#F8746E"
    />
    <path d="M69 126.5L97 104L93.5 140L69 126.5Z" fill="#D9E2E9" />
    <path
      d="M138 182.5C137.743 134.67 132.241 116.163 105.5 114L79 175.5L71.5 182.5H138Z"
      fill="#384868"
    />
    <path
      d="M108 114.5L97 104C94.8933 130.698 90.351 146.725 78.5 176.5L108 138.5L100.5 128L110 130L108 114.5Z"
      fill="#43567C"
    />
    <path d="M69 126.5L42 104L45.5 140L69 126.5Z" fill="#D9E2E9" />
    <path
      d="M1 182.5C1.2569 134.67 6.75854 116.163 33.5 114L60 175.5L67.5 182.5H1Z"
      fill="#384868"
    />
    <path
      d="M31 114.5L42 104C44.1067 130.698 48.649 146.725 60.5 176.5L31 138.5L38.5 128L29 130L31 114.5Z"
      fill="#43567C"
    />
  </svg>
);
