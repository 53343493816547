import { Heading } from "@gle/base-ui.typography.heading";
import { Text } from "@gle/base-ui.typography.text";
import { CheckIcon } from "../../../components/CheckIcon";

export const Completed = () => {
  return (
    <>
      <Heading level={3} className="mb-20">
        Congratulazioni!
      </Heading>

      <Text className="mb-20">
        I tuoi dati sono stati inviati correttamente alla società. Grazie per
        essere entrato a far parte della rivoluzione digitale del mondo
        sportivo.
      </Text>

      <CheckIcon />
    </>
  );
};
