const logos = {
  white: `${window._env_.PUBLIC_URL}/images/golee_white.png`,
  red: `${window._env_.PUBLIC_URL}/images/golee_red.png`,
};

const Component = ({
  color = "white",
  width = 100,
}: {
  color?: "red" | "white";
  width?: number;
}) => {
  return (
    <a href="https://golee.it" target={"_blank"}>
      <img src={logos[color]} alt="Golee" style={{ width }} />
    </a>
  );
};

export default Component;
