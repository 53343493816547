import api from "./api";
import { TPublicClub } from "./club.type";
import axios from "./interceptor";

const service = {
  getBySlug: async (slug: string): Promise<TPublicClub> => {
    try {
      const res = await axios.get(api.getBySlug(slug));
      return res.data;
    } catch (err) {
      throw err;
    }
  },
};

export default service;
