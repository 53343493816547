import axios from "axios";
import { Enroll } from "../redux/Enroll/types";

export const createDocument = async (
  entityId: string,
  data: {
    name: string;
    url: string;
    mimeType: string;
    organizationId: string;
  }
) => {
  try {
    await axios.post(
      `${window._env_.REACT_APP_API_URL_DOCUMENTS_SERVICE}documents/public`,
      {
        ...data,
        ref_entity: entityId,
        type_entity: "enroll",
      }
    );
  } catch (err) {
    throw err;
  }
};

export const createDocuments = async (
  entityId: string,
  data: {
    name: string;
    url: string;
    mimeType: string;
    organizationId: string;
  }[]
) => {
  try {
    await Promise.all(data.map((t) => createDocument(entityId, t)));
  } catch (err) {
    throw err;
  }
};

export const saveAttachments = async (
  _id: string,
  files: Enroll["data"]["extra"]["files"],
  organizationId: string
) => {
  try {
    if (files && Object.keys(files).length) {
      await createDocuments(
        _id,
        Object.keys(files).map((t) => ({
          name: files[t].name,
          url: files[t].url,
          mimeType: files[t].mime,
          organizationId,
        }))
      );
    }
  } catch (err) {
    console.error("Could not store attachments");
  }
};

export const saveAttachmentsAsArray = async (
  _id: string,
  files: { date: string; name: string; url: string; mime: string }[],
  organizationId: string
) => {
  try {
    if (files && files.length) {
      await createDocuments(
        _id,
        files.map((f) => ({ ...f, mimeType: f.mime, organizationId }))
      );
    }
  } catch (err) {
    console.error("Could not store attachments");
  }
};
