import { ACTIONS, Actions, EnrollState } from "./types";

const initialState: EnrollState = {
  type: "athlete",
  data: {
    personal: {
      name: undefined,
      surname: undefined,
      fiscal_code: undefined,
      privacy: false,
      birthdate: undefined,
      birthplace: undefined,
      sex: undefined,
      recap: {
        email: undefined,
        phone: undefined,
      },
    },
    extra: {
      nationality: undefined,
      medical_examination_expiration_date: undefined,
      permesso_soggiorno_expiration_date: undefined,
    },
    address: {
      nation: undefined,
      region: undefined,
      province: undefined,
      town: undefined,
      address: undefined,
      civic_number: undefined,
      cap: undefined,
    },
    parent: {
      name: undefined,
      surname: undefined,
      email: undefined,
      phone: undefined,
      address: {
        nation: undefined,
        region: undefined,
        province: undefined,
        town: undefined,
        address: undefined,
        civic_number: undefined,
        cap: undefined,
      },
      recap: {
        email: undefined,
        phone: undefined,
      },
    },
    parent_2: {
      name: undefined,
      surname: undefined,
      email: undefined,
      phone: undefined,
      address: {
        nation: undefined,
        region: undefined,
        province: undefined,
        town: undefined,
        address: undefined,
        civic_number: undefined,
        cap: undefined,
      },
      recap: {
        email: undefined,
        phone: undefined,
      },
    },
  },
};

export default function enrollReducer(
  state = initialState,
  action: Actions
): EnrollState {
  switch (action.type) {
    case ACTIONS.RESET_ENROLL_DATA: {
      return {
        type: state.type,
        data: initialState.data,
      };
    }

    case ACTIONS.SET_ENROLL_TYPE: {
      return {
        ...state,
        type: action.payload,
      };
    }

    case ACTIONS.UPDATE_ENROLL_PERSONAL_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          personal: {
            ...state.data.personal,
            ...action.payload,
          },
        },
      };
    }

    case ACTIONS.UPDATE_ENROLL_EXTRA_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          extra: {
            ...state.data.extra,
            ...action.payload,
          },
        },
      };
    }

    case ACTIONS.UPDATE_ENROLL_ADDRESS_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          address: {
            ...state.data.address,
            ...action.payload,
          },
        },
      };
    }

    case ACTIONS.UPDATE_ENROLL_PARENTS_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          parent: {
            ...state.data.parent,
            ...action.payload.parent,
          },
          parent_2: {
            ...state.data.parent,
            ...action.payload.parent_2,
          },
        },
      };
    }

    default:
      return state;
  }
}
