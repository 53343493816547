import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { FormProps } from ".";
import { FiscalCode } from "../../modules/@codice-fiscale";
import { Enroll } from "../../redux/Enroll/types";
import { AppState } from "../../redux/types";
import { InputField } from "../Inputs/Input";
import { InputPhoneField, phoneValidator } from "../Inputs/InputPhone";
import { emailValidator } from "../Inputs/helpers/email-validator";
import { FieldLabel } from "../field-label";

const Component = ({ ...props }: FormProps<Enroll["data"]["personal"]>) => {
  const { messages } = useSelector((state: AppState) => state.Locale);

  const schema = Yup.object().shape({
    name: Yup.string().required(messages["field_required"]),
    surname: Yup.string().required(messages["field_required"]),
    fiscal_code: Yup.string()
      .required(messages["field_required"])
      .test(
        "fiscal_code",
        messages["fiscal_code_not_valid"],
        FiscalCode.isValid
      ),
    recap: Yup.object().shape({
      email: Yup.string()
        .email(messages["email_not_valid"])
        .required(messages["field_required"])
        .test("email", messages["email_not_valid"], (val) =>
          emailValidator(val, true)
        )
        .trim(),
      phone: Yup.string()
        .test("phone", messages["invalid_phone_number"], (val) =>
          phoneValidator(val, true)
        )
        .nullable(),
    }),
  });

  const onSubmit = (values: any) => {
    props.onSubmit({
      ...values,
      recap: { ...values.recap, email: values.recap.email.trim() },
      ...FiscalCode.reverse(values.fiscal_code),
    });
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      initialValues={{ ...props.initialValues, files: [] }}
      validateOnMount={false}
      validateOnBlur={true}
      validateOnChange={false}
      onSubmit={onSubmit}
      innerRef={props.formRef}
    >
      {(formContext) => {
        return (
          <Form>
            <div className="flex gap-20 flex-column">
              <InputField
                name="name"
                label={
                  <FieldLabel
                    config={{
                      label: props.athlete
                        ? `Nome dell'atleta`
                        : messages["name"],
                      required: true,
                    }}
                  />
                }
                pascalCase
              />

              <InputField
                name="surname"
                label={
                  <FieldLabel
                    config={{
                      required: true,
                      label: props.athlete
                        ? `Cognome dell'atleta`
                        : messages["surname"],
                    }}
                  />
                }
                pascalCase
              />

              <InputField
                name="fiscal_code"
                label={
                  <FieldLabel
                    config={{
                      required: true,
                      label: props.athlete
                        ? `Codice fiscale dell'atleta`
                        : messages["fiscal_code"],
                    }}
                  />
                }
                upperCase
              />

              <InputField
                name="recap.email"
                label={
                  <FieldLabel
                    config={{
                      required: true,
                      label: props.athlete
                        ? `Email di contatto per la società`
                        : messages["email"],
                    }}
                  />
                }
                inputMode="email"
                lowerCase
              />

              <InputPhoneField
                name="recap.phone"
                label={
                  props.athlete
                    ? `Telefono di contatto per la società`
                    : messages["phone"]
                }
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Component;
