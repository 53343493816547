import Club from "./Club/reducer";
import Locale from "./Locale/reducer";
import Locations from "./Locations/reducer";
import Enroll from './Enroll/reducer';

const _ = {
  Club,
  Locale,
  Locations,
  Enroll
};

export default _;
