import axios from './interceptor';
import api from './api';

const service = {
	createEnroll: async (data: any): Promise<any> => {
		try {
			const res = await axios.post(api.create(), data);
			return res.data;
		} catch (err) {
			throw err;
		}
	},


	getEnrollSettings: async (organizationId: string) => {
		try {
			const response = await axios.get(api.getEnrollSettings(organizationId))
			return response.data;
		} catch (err) {
			console.log(err);
		}
	}
};

export default service;
