import moment from 'moment';
import DatePicker from './Component';
import DatePickerField from './Field';

export { DatePicker, DatePickerField };

export const dateValidator = (
	value?: string,
	outputFormat?: string,
	nullable?: boolean
): boolean => {
	if (nullable) {
		if (!value) {
			return true;
		}
	}

	if (!value) {
		return false;
	}

	const date = moment(value, outputFormat);

	return date.isValid() && !value.includes('_');
};
