import { Heading } from "@gle/base-ui.typography.heading";
import { Text } from "@gle/base-ui.typography.text";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/types";
import { AthleteIcon, ManagerialStaffIcon, TechincalStaffIcon } from "./avatar";
import { RoleCard } from "./card";

export type Role = {
  avatar: ReactElement;
  name: string;
  description: string;
  path: string;
};

const Container = () => {
  const { colors } = useSelector((state: AppState) => state.Club);

  const roles: Role[] = [
    {
      avatar: <AthleteIcon colors={colors} size={35} />,
      name: "Atleta",
      path: "athlete",
      description: "Sono un genitore o un atleta di questo club",
    },
    {
      avatar: <TechincalStaffIcon colors={colors} size={35} />,
      name: "Tecnico",
      path: "technical-staff",
      description: "Sono un tecnico di questo club",
    },
    {
      avatar: <ManagerialStaffIcon size={35} />,
      name: "Dirigente",
      path: "managerial-staff",
      description: "Sono un dirigente di questo club",
    },
  ];

  return (
    <>
      <div className="container">
        <Heading level={3} style={{ marginBottom: 4 }}>
          Per quale ruolo vuoi inserire i dati?
        </Heading>
        <Text style={{ marginBottom: 20 }}>
          I dati inseriti prima di essere confermati verranno verificati dal tuo
          club
        </Text>
        {roles.map((role) => (
          <RoleCard role={role} key={role.name} />
        ))}
      </div>
    </>
  );
};

export default Container;
