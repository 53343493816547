import { Heading } from "@gle/base-ui.typography.heading";
import { Text } from "@gle/base-ui.typography.text";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { FormProps } from ".";
import { FiscalCode } from "../../modules/@codice-fiscale";
import { Enroll } from "../../redux/Enroll/types";
import { AppState } from "../../redux/types";
import { CheckBox } from "../Inputs/CheckBox";
import { InputField } from "../Inputs/Input";
import { InputPhoneField } from "../Inputs/InputPhone";
import { SelectField } from "../Inputs/Select";
import { emailValidator } from "../Inputs/helpers/email-validator";
import { FieldLabel } from "../field-label";
import { addressSchema, recapSchema } from "./schemas";

enum EKinship {
  FTR = "FTR",
  MTR = "MTR",
  LGG = "LGG",
  SBL = "SBL",
  OTH = "OTH",
}

const Component = ({
  ...props
}: FormProps<{
  parent: Enroll["data"]["parent"];
  parent_2: Enroll["data"]["parent_2"];
}>) => {
  const { messages } = useSelector((state: AppState) => state.Locale);

  const [anotherParent, setAnotherParent] = useState<boolean>(false);

  const { countries, regions, provinces } = useSelector(
    (state: AppState) => state.Locations
  );

  const schema = Yup.object().shape({
    parent: Yup.object().shape({
      name: Yup.string().required(messages["field_required"]),
      surname: Yup.string().required(messages["field_required"]),
      fiscal_code: Yup.string()
        .test("fiscal_code", messages["fiscal_code_not_valid"], (value) =>
          value ? FiscalCode.isValid(value) : true
        )
        .nullable(),
      recap: recapSchema(messages),
      address: addressSchema(messages),
    }),
    parent_2: Yup.object().shape({
      name: Yup.string().nullable(),
      surname: Yup.string().nullable(),
      recap: Yup.object().shape({
        phone: Yup.string().nullable(),
        email: Yup.string()
          .email(messages["email_not_valid"])
          .trim()
          .test("email", messages["email_not_valid"], (val) =>
            emailValidator(val, true)
          ),
      }),
      address: addressSchema(messages),
    }),
  });

  const renderAddress = (key: string) => {
    return (
      <>
        <Heading level={3} style={{ marginTop: 20 }}>
          Indirizzo genitore
          <Text
            weight={"light"}
            color="gray-light"
            style={{ display: "inline-block" }}
          ></Text>
        </Heading>
        <SelectField
          name={`${key}.address.nation`}
          label={messages["nation"]}
          isClearable
          options={Object.keys(countries).map((key) => ({
            value: key,
            label: countries[key].label,
          }))}
        />
        <SelectField
          name={`${key}.address.region`}
          label={messages["region"]}
          isClearable
          options={Object.keys(regions).map((key) => ({
            value: key,
            label: regions[key].label,
          }))}
        />
        <SelectField
          name={`${key}.address.province`}
          label={messages["province"]}
          isClearable
          options={Object.keys(provinces).map((key) => ({
            value: key,
            label: provinces[key].label,
          }))}
        />
        <div className="flex flex-row gap-20">
          <InputField
            name={`${key}.address.address`}
            label={messages["address"]}
            style={{ flex: 0.75 }}
          />

          <InputField
            name={`${key}.address.civic_number`}
            label={messages["civic_number"]}
            style={{ flex: 0.25 }}
          />
        </div>
        <div className="flex flex-row gap-20">
          <InputField
            name={`${key}.address.town`}
            label={messages["town"]}
            style={{ flex: 0.75 }}
          />
          <InputField
            name={`${key}.address.cap`}
            label={messages["cap"]}
            style={{ flex: 0.25 }}
          />
        </div>
      </>
    );
  };

  const renderFields = (key: string) => {
    return (
      <>
        <InputField
          name={`${key}.name`}
          label={
            <FieldLabel
              config={{
                required: true,
                label: messages["name"],
              }}
            />
          }
        />
        <InputField
          name={`${key}.surname`}
          label={
            <FieldLabel
              config={{
                required: true,
                label: messages["surname"],
              }}
            />
          }
        />

        <InputField
          name={`${key}.recap.email`}
          label={
            <FieldLabel
              config={{
                required: true,
                label: messages["email"],
              }}
            />
          }
          lowerCase
        />

        <SelectField
          key={"kinship"}
          name={`${key}.kinship`}
          label={messages["kinship"]}
          options={Object.keys(EKinship).map((_) => ({
            value: _,
            label: messages[`kinship.${_}`],
          }))}
        />

        <InputField
          name={`${key}.fiscal_code`}
          label={messages["fiscal_code"]}
          upperCase
        />

        <InputPhoneField
          name={`${key}.recap.phone`}
          label={messages["phone"]}
        />

        {renderAddress(key)}
      </>
    );
  };

  const onSubmit = (values: any) => {
    props.onSubmit({
      ...values,
      parent: {
        ...values.parent,
        recap: {
          ...values.parent.recap,
          email: values.parent.recap.email.trim(),
        },
      },
      parent_2: {
        ...values.parent_2,
        recap: {
          ...values.parent_2.recap,
          email: values.parent_2.recap.email.trim(),
        },
      },
    });
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      initialValues={props.initialValues}
      validateOnMount={false}
      validateOnBlur={true}
      validateOnChange={false}
      onSubmit={onSubmit}
      innerRef={props.formRef}
    >
      {(formContext) => {
        return (
          <Form>
            <div className="flex gap-20 flex-column">
              {renderFields("parent")}
            </div>

            <CheckBox
              onChange={setAnotherParent}
              value={anotherParent}
              name="another_parent"
              label="Voglio inserire un altro genitore/tutore legale"
            />

            {anotherParent && (
              <div className="flex gap-10 flex-column">
                <Heading level={3} style={{ marginTop: 20 }}>
                  {messages["parent_data"]} 2
                  <Text
                    weight={"light"}
                    color="gray-light"
                    style={{ display: "inline-block", marginLeft: 5 }}
                  ></Text>
                </Heading>
                {renderFields("parent_2")}
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default Component;
