import styled from "styled-components";
import { Sider } from "./components/Sider";

type TProps = {
  children: React.ReactChild;
};

const Wrapper = styled.div`
  @media screen and (min-width: 769px) {
    display: grid;
    height: 100vh;
    overflow: hidden;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 2fr;
  }

  @media screen and (max-width: 768px) {
    display: block;
    overflow: auto;
  }
`;

const Content = styled.div`
  overflow: auto;
  padding: 35px;

  @media screen and (max-width: 768px) {
    padding: 15px;
  }
`;

const Layout = ({ ...props }: TProps) => {
  return (
    <Wrapper>
      <Sider />
      <Content>{props.children}</Content>
    </Wrapper>
  );
};

export default Layout;
