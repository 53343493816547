import styled from "styled-components";

export type TextProps = {
  /**
   * HTML input type.
   */
  type?: "text" | "textarea" | "number";

  /**
   * Name of the field.
   */
  name: string;

  /**
   * Value of the field.
   */
  value?: string;

  /**
   * Placeholder of the field.
   */
  placeholder?: string;

  /**
   * Label of the field.
   */
  label: string | React.ReactNode;

  /**
   * Min value (for number inputs).
   */
  min?: number;

  /**
   * Max value (for number inputs).
   */
  max?: number;

  /**
   * Field input mode.
   */
  inputMode?: React.HTMLAttributes<HTMLInputElement>["inputMode"];

  /**
   * Force the input to be all uppercase. Works for text and textarea.
   */
  upperCase?: boolean;

  /**
   * Force the input to be all lowercase. Works for text and textarea.
   */
  lowerCase?: boolean;

  /**
   * Force the input to be all capitalize. Works for text and textarea.
   */
  pascalCase?: boolean;

  /**
   * On change trigger.
   */
  onChange?: (ev: string) => void;

  /**
   * On blur trigger.
   */
  onBlur?: (ev: any) => void;

  /**
   * On focus trigger.
   */
  onFocus?: (ev: any) => void;

  style?: React.CSSProperties;
};

const Wrapper = styled.div`
  width: 100%;

  &.h-auto {
    height: auto;
  }

  label {
    font-size: var(--s-size);
    display: block;
    color: var(--gray-light-color);
    margin-bottom: 2px;
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
    padding: 0 7px;
    border: 1px solid transparent;
    border-radius: 10px;
    appearance: none;
    outline: none;
    background: transparent;
    font-size: var(--m-size);
    font-family: var(--font-family);
    resize: vertical;
    min-height: 100px;
    max-height: 500px;
    padding-top: 7px;
    background-color: #f3f3f4;

    &:focus,
    &:active,
    &:hover {
      border-color: var(--gray-lightest-color);
      background-color: #fff;
    }
  }

  input {
    box-sizing: border-box;
    width: 100%;
    padding: 0 7px;
    border: 1px solid transparent;
    border-radius: 10px;
    appearance: none;
    outline: none;
    background: transparent;
    height: 48px;
    font-size: var(--m-size);
    font-family: var(--font-family);
    background-color: #f3f3f4;

    &:focus,
    &:active,
    &:hover {
      border-color: var(--gray-lightest-color);
      background-color: #fff;
    }
  }
`;

const PascalCase = (value: string): string => {
  return value.replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase();
  });
};

const Component = ({ type = "text", style = {}, ...props }: TextProps) => {
  const transformValue = (value: string): string => {
    switch (true) {
      case props.lowerCase: {
        return value.toLowerCase();
      }

      case props.upperCase: {
        return value.toUpperCase();
      }

      case props.pascalCase: {
        return PascalCase(value);
      }

      default: {
        return value;
      }
    }
  };

  const renderNumber = () => {
    return (
      <input
        type="number"
        value={props.value}
        min={props.min}
        max={props.max}
        placeholder={props.placeholder}
        inputMode={props.inputMode}
        onChange={(ev) => {
          props.onChange?.(ev.target.value);
        }}
        onBlur={props.onBlur}
      />
    );
  };

  const renderText = () => {
    return (
      <input
        type="text"
        placeholder={props.placeholder}
        value={props.value}
        inputMode={props.inputMode}
        onChange={(ev) => {
          props.onChange?.(transformValue(ev.target.value));
        }}
        onBlur={props.onBlur}
      />
    );
  };

  const renderTextArea = () => {
    return (
      <textarea
        placeholder={props.placeholder}
        value={props.value}
        inputMode={props.inputMode}
        onChange={(ev) => {
          props.onChange?.(transformValue(ev.target.value));
        }}
        onBlur={props.onBlur}
      />
    );
  };

  return (
    <Wrapper className={`${type === "textarea" ? "h-auto" : ""}`} style={style}>
      {props.label && <label htmlFor={props.name}>{props.label}</label>}
      {type === "text" && renderText()}
      {type === "textarea" && renderTextArea()}
      {type === "number" && renderNumber()}
    </Wrapper>
  );
};

export default Component;
