import moment from "moment";
import React, { ChangeEvent, useRef, useState } from "react";

import { Button, ButtonProps } from "@gle/base-ui.buttons.button";
import { Text } from "@gle/base-ui.typography.text";
import { IconTrash, IconUpload } from "@golee/gle-icons";

import { CircleButton } from "@gle/base-ui.buttons.circle-button";
import axios from "axios";

type FileValue = {
  url?: string;
  name?: string;
  title?: string;
  date?: string;
  mime?: string;
};

type FileUploaderProps = {
  id?: string;
  api: {
    baseURL: string;
  };
  onValueChanged?: (value?: FileValue[]) => void;
  onUploadStarted?: (file: File) => void;
  onStatusChanged?: (status: "uploading" | "uploaded") => void;
  onUploadCompleted?: (value: FileValue[], name: string) => void;
  onError?: (err?: any) => void;
  value?: FileValue[];
  allowDelete?: boolean;
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  style?: React.CSSProperties;
  buttonLabel?: string;
  buttonType?: ButtonProps["level"];
  buttonSize?: ButtonProps["size"];
  buttonColor?: ButtonProps["color"];
  hideDocumentLabel?: boolean;
  mimeTypes?: string;
};

export const MultiFileUploader = ({
  value,
  style = {},
  buttonType = "secondary",
  buttonSize = "s",
  buttonColor = "primary",
  hideDocumentLabel,
  mimeTypes = "application/pdf",
  ...props
}: FileUploaderProps) => {
  const inputRef = useRef<any>();
  const [isUploading, setIsUploading] = useState(false);

  const uploadFile = async (file: File) => {
    let formData = new FormData();
    formData.append("file", file);

    props.onUploadStarted?.(file);
    props.onStatusChanged?.("uploading");

    setIsUploading(true);

    try {
      const response = await axios.post(props.api.baseURL, formData);

      const url = response.data.magic_url || response.data.public_url;

      var newValue;

      const newObject: FileValue = {
        name: file.name,
        date: moment().format("DD/MM/YYYY"),
        url,
        mime: response.data.mime,
      };

      if (Array.isArray(value)) {
        newValue = value.concat(newObject);
      } else {
        newValue = [newObject];
      }

      props.onValueChanged?.(newValue);
      props.onStatusChanged?.("uploaded");
      props.onUploadCompleted?.(newValue, file.name);

      return false;
    } catch (err) {
      props.onStatusChanged?.("uploaded");
      props.onError?.(err);

      return false;
    } finally {
      setIsUploading(false);
      inputRef.current.value = "";
    }
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) return;

    const file = event.target.files[0];

    uploadFile(file);
  };

  return (
    <div className={"file-uploader"}>
      {props.label && (
        <Text
          style={{ marginTop: 20 }}
          color={
            props.disabled && !props.readOnly ? "gray-light" : "gray-lighter"
          }
        >
          {props.label}
        </Text>
      )}

      {!!value && (
        <div>
          {Array.isArray(value) ? (
            <div>
              {value.map((t, index) => (
                <FileName
                  value={t}
                  onDelete={
                    props.allowDelete
                      ? () => {
                          props.onValueChanged?.(
                            value.filter((x: any, i: number) => i !== index)
                          );
                        }
                      : undefined
                  }
                />
              ))}
            </div>
          ) : (
            <FileName
              value={value}
              onDelete={
                props.allowDelete
                  ? () => {
                      props.onValueChanged?.();
                    }
                  : undefined
              }
            />
          )}
        </div>
      )}

      <div>
        <input
          type="file"
          accept={mimeTypes}
          hidden
          ref={inputRef}
          onChange={handleFileUpload}
        />
        <Button
          color="ghost"
          level="secondary"
          isLoading={isUploading}
          onClick={() => inputRef.current.click()}
          disabled={props.readOnly}
        >
          {isUploading ? (
            <>Caricamento...</>
          ) : (
            <>
              <IconUpload />

              {props.buttonLabel ? (
                props.buttonLabel
              ) : (
                <>{`Carica ${!hideDocumentLabel ? "documenti" : ""}`}</>
              )}
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

const FileName = ({
  value,
  ...props
}: {
  value: FileValue;
  onDelete?: () => void;
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center", marginBlock: 5 }}>
      <a
        href={value.url}
        target={"_blank"}
        style={{ color: "var(--blue-color)" }}
      >
        {value.name ||
          value.title ||
          moment(value.date, "DD/MM/YYYY").format("DD/MM/YYYY")}
      </a>
      {props.onDelete && (
        <CircleButton
          onClick={props.onDelete}
          color="danger"
          style={{ marginLeft: 10, height: "auto" }}
        >
          <IconTrash
            style={{
              width: 15,
            }}
          />
        </CircleButton>
      )}
    </div>
  );
};
