import { Heading } from "@gle/base-ui.typography.heading";
import { Text } from "@gle/base-ui.typography.text";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { FormProps } from ".";
import { Enroll } from "../../redux/Enroll/types";
import { AppState } from "../../redux/types";
import { dateValidator } from "../Inputs/DatePicker";
import { InputField } from "../Inputs/Input";
import { SelectField } from "../Inputs/Select";
import { MultiFileUploader } from "../multi-file-uploader";

const Component = ({ ...props }: FormProps<Enroll["data"]["extra"]>) => {
  const { countries } = useSelector((state: AppState) => state.Locations);
  const { messages } = useSelector((state: AppState) => state.Locale);

  const schema = Yup.object().shape({
    medical_examination_expiration_date: Yup.string()
      .test(
        "medical_examination_expiration_date",
        messages["invalid_date"],
        (value) => dateValidator(value, "DD/MM/YYYY", true)
      )
      .nullable(),
  });

  const onSubmit = (values: any) => {
    props.onSubmit(values);
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      initialValues={{ ...props.initialValues, files: [] }}
      validateOnMount={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      innerRef={props.formRef}
    >
      {(formContext) => {
        return (
          <Form>
            <div className="flex gap-20 flex-column">
              <SelectField
                name="nationality"
                label={messages["nationality"]}
                options={Object.keys(countries).map((key) => ({
                  value: key,
                  label: countries[key].label,
                }))}
              />

              <InputField
                type="textarea"
                name="note.text"
                label={messages["notes"]}
              />

              <div style={{ marginTop: 10, marginBottom: 40 }}>
                <Heading level={3} style={{ marginBottom: 5 }}>
                  Allegati
                </Heading>

                <Text style={{ marginBottom: 10 }}>
                  Carica qui i documenti che devi fornire alla società sportiva.
                </Text>

                <MultiFileUploader
                  allowDelete
                  value={formContext.values.files}
                  onValueChanged={(value) =>
                    formContext.setFieldValue("files", value)
                  }
                  mimeTypes={
                    "application/pdf,text/csv,image/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain"
                  }
                  api={{
                    baseURL: `${window._env_.REACT_APP_API_URL_PEPA_SERVICE}files`,
                  }}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Component;
