import { useField } from "formik";
import Select, { StylesConfig } from "react-select";
import styled from "styled-components";

export type SelectProps = {
  /**
   * Placeholder of the select.
   */
  placeholder?: string;

  /**
   * Array of selectable options
   */
  options?: { value: any; label: string }[];

  /**
   * On change trigger.
   */
  onChange?: (e?: any) => void;

  /**
   * On blur trigger.
   */
  onBlur?: (e?: any) => void;

  /**
   * On focus trigger.
   */
  onFocus?: (e?: any) => void;

  /**
   * Actual value of the field.
   */
  value?: any;

  /**
   * Disabled state of the field.
   */
  disabled?: boolean;

  /**
   * Define if field can be clearalbe (nullable).
   */
  isClearable?: boolean;

  /**
   * Name of the field.
   */
  name?: string;

  /**
   * Label of the field.
   */
  label: string | React.ReactNode;

  /**
   * Define if can be selected more than one value
   */
  isMulti?: boolean;
};

const Wrapper = styled.div`
  width: 100%;

  label {
    margin-bottom: 2px;
    font-size: var(--s-size);
    display: block;
    color: var(--gray-light-color);
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -ms-appearance: none; /* get rid of default appearance for IE8, 9 and 10*/
    box-sizing: border-box;
    width: 100%;
    padding: 0 7px;
    border: 1px solid transparent;
    border-radius: 10px;
    appearance: none;
    outline: none;
    background: transparent;
    height: 48px;
    font-size: var(--m-size);
    font-family: var(--font-family);
    background-color: #f3f3f4;

    &:focus,
    &:active,
    &:hover {
      border-color: var(--gray-lightest-color);
      background-color: #fff;
    }
  }
`;

const Component = ({ ...props }: SelectProps) => {
  const [field, meta, helpers] = useField(props as any);

  const getMultiValue = () => {
    if (!props.options || !props.options.length) {
      return [];
    }

    if (props.value === undefined) return [];

    return props.value.map((t: any) => ({
      value: t,
      label: props.options?.find((opt: any) => opt.value === t)?.label,
    }));
  };

  return (
    <Wrapper>
      {props.label && <label htmlFor={props.name}>{props.label}</label>}

      <Select
        {...props}
        {...field}
        className="select"
        id={props.name}
        isDisabled={props.disabled}
        classNamePrefix="select"
        noOptionsMessage={() => "Nessun risultato"}
        placeholder={props.placeholder || "Seleziona..."}
        styles={selectStyles}
        value={
          props.isMulti
            ? getMultiValue()
            : props.value
            ? {
                value: props.value,
                label: props.options?.find((t) => t.value === props.value)
                  ?.label,
              }
            : undefined
        }
        options={props.options}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        isClearable={props.isClearable}
        onChange={(option: any) => {
          if (props.isMulti) {
            const values = option?.map((t: any) => {
              if (t.hasOwnProperty("value")) {
                return t.value;
              }

              return t;
            });

            helpers.setValue(values);
            props.onChange?.(values);
          } else {
            helpers.setValue(option ? option.value : null);
            props.onChange?.(option);
          }
        }}
        name={props.name}
        blurInputOnSelect
      />
    </Wrapper>
  );
};

export default Component;

export const selectStyles: StylesConfig = {
  container: (provided) => ({
    ...provided,
    minWidth: "200px",
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#f3f3f4",
    borderColor: "transparent",
    borderRadius: 10,
    height: 48,
    ":hover": {
      backgroundColor: "#fff",
      borderColor: "var(--gray-lightest-color)",
    },
  }),
  input: (provided) => ({
    ...provided,
    fontSize: "var(--m-size)",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "var(--m-size)",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "var(--m-size)",
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: "var(--m-size)",
  }),
};
