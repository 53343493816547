import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@gle/base-ui.tooltip";
import { Button } from "@gle/base-ui.buttons.button";
import { Text } from "@gle/base-ui.typography.text";
import { AppState } from "../../redux/types";

type TProps = {
  url: string;
  onShare?: () => void;
};

const Component = ({ url, ...props }: TProps) => {
  const messages = useSelector((store: AppState) => store.Locale.messages);
  const club = useSelector((store: AppState) => store.Club);

  const [copied, setCopied] = useState<boolean>(false);
  const [showCopiedTooltip, setShowCopiedTooltip] = useState<boolean>(false);

  const sharableMessage = `Ciao! Utilizza questo link ${url} per iscriverti alla società ${club.name}.`;

  const copyUrl = () => {
    try {
      navigator.clipboard.writeText(url!);
      setCopied(true);
      setShowCopiedTooltip(true);

      setTimeout(() => {
        setShowCopiedTooltip(false);
      }, 1000);
    } catch (err) {}

    props.onShare?.();
  };

  return (
    <>
      <div style={{ marginTop: 20 }}>
        <Text style={{ marginBottom: 20 }} color="accent" weight="medium">
          {url}
        </Text>

        <div className="flex justify-center md-hidden">
          <Button
            onClick={() => {
              try {
                navigator
                  .share({
                    text: `Utilizza questo link per iscriverti alla società ${club.name}.\n\n`,
                    url,
                  })
                  .then(() => {})
                  .catch((err) => {});

                props.onShare?.();
              } catch (err) {}
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#fff"
              width={20}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
              />
            </svg>
            Condividi link
          </Button>
        </div>

        <div className="flex items-center gap-20 sm-hidden">
          <Tooltip
            visible={showCopiedTooltip}
            content={messages["copied_in_clipboard"]}
            placement="bottom"
          >
            <Button onClick={copyUrl}>
              <i className="las la-copy"></i>
              Copia link
            </Button>
          </Tooltip>

          <Button
            level="secondary"
            onClick={() => {
              props.onShare?.();
              window.open(`https://wa.me/?text=${sharableMessage}`, "_blank");
            }}
          >
            <i className="lab la-whatsapp"></i>
            Invia su Whatsapp
          </Button>
          <Button
            level="secondary"
            onClick={() => {
              props.onShare?.();
              window.open(
                `mailto:?to=&body=${sharableMessage}&subject=Golee | Preiscrizione`,
                "_blank"
              );
            }}
          >
            <i className="las la-envelope"></i>
            Invia per email
          </Button>
        </div>
      </div>
    </>
  );
};

export default Component;
