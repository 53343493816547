import {
  ACTIONS,
  Enroll,
  ResetAction,
  SetTypeAction,
  UpdateAddressAction,
  UpdateExtraAction,
  UpdateParentsAction,
  UpdatePersonalAction,
} from "./types";

export function updatePersonalData(
  data: Enroll["data"]["personal"]
): UpdatePersonalAction {
  return {
    type: ACTIONS.UPDATE_ENROLL_PERSONAL_DATA,
    payload: data,
  };
}

export function updateExtraData(
  data: Enroll["data"]["extra"]
): UpdateExtraAction {
  return {
    type: ACTIONS.UPDATE_ENROLL_EXTRA_DATA,
    payload: data,
  };
}

export function updateAddressData(
  data: Enroll["data"]["address"]
): UpdateAddressAction {
  return {
    type: ACTIONS.UPDATE_ENROLL_ADDRESS_DATA,
    payload: data,
  };
}

export function updateParentsData(data: {
  parent: Enroll["data"]["parent"];
  parent_2: Enroll["data"]["parent_2"];
}): UpdateParentsAction {
  return {
    type: ACTIONS.UPDATE_ENROLL_PARENTS_DATA,
    payload: data,
  };
}

export function setType(type: Enroll["type"]): SetTypeAction {
  return {
    type: ACTIONS.SET_ENROLL_TYPE,
    payload: type,
  };
}

export function resetData(): ResetAction {
  return {
    type: ACTIONS.RESET_ENROLL_DATA,
  };
}
