import { ConnectedRouter } from "connected-react-router/immutable";
import { Route, Switch } from "react-router-dom";
import { Athlete } from "../pages/Athlete";

import { Club } from "../pages/Club";
import { ManagerialStaff } from "../pages/ManagerialStaff";
import { TechnicalStaff } from "../pages/TechnicalStaff";
import { EPath } from "./types/path.enum";

const AppRouter = ({ history }: any) => {
  return (
    <>
      <ConnectedRouter history={history}>
        <Switch>
          <Route
            path={"/:club"}
            render={(params) => (
              <>
                <Route exact path={`${params.match.url}`} component={Club} />

                <Route
                  path={`${params.match.url}${EPath.technical_staff}`}
                  component={TechnicalStaff}
                />

                <Route
                  path={`${params.match.url}${EPath.managerial_staff}`}
                  component={ManagerialStaff}
                />

                <Route
                  path={`${params.match.url}${EPath.athlete}`}
                  component={Athlete}
                />
              </>
            )}
          />
        </Switch>
      </ConnectedRouter>
    </>
  );
};

export default AppRouter;
