import * as yup from "yup";
import { FieldConfig } from "./types";

export const createValidationSchema = (
  schema: any,
  config: { key: string; type: FieldConfig["type"]; required: boolean }
) => {
  let validationType =
    config.type === "CHECKBOX"
      ? yup.boolean()
      : config.type === 'SELECT_MULTI_VALUES'
        ? yup.array().of(yup.string().nullable())
        : config.type === "FILE"
          ? yup.mixed().nullable()
          : yup.string().nullable();

  let validations = []

  if (config.required) {
    validations.push({
      type: "required",
      params: ["Campo richiesto"],
    });

    if (validationType === yup.boolean()) {
      validations.push({
        type: "oneOf",
        params: [[true], "Il consenso è obbligatorio"],
      });
    }
  }

  validations.forEach((validation) => {
    const { params, type } = validation;

    // @ts-ignore
    if (!validationType[type]) {
      return;
    }

    // @ts-ignore
    validationType = validationType[type](...params);
  });

  schema[config.key] = validationType;
  return schema;
};