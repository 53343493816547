import { ReactNode } from "react";

export const FieldLabel = (props: {
  config: { label: ReactNode; required?: boolean };
}) => {
  return (
    <>
      {props.config.label}{" "}
      {props.config.required && (
        <span style={{ color: "var(--danger-color)" }}>*</span>
      )}
    </>
  );
};
