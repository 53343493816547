import * as Yup from "yup";
import { phoneValidator } from "../Inputs/InputPhone";
import { emailValidator } from "../Inputs/helpers/email-validator";

export const addressSchema = (messages: any, required?: boolean) => {
  if (required) {
    return Yup.object().shape({
      nation: Yup.string().nullable().required(messages["field_required"]),
      region: Yup.string().nullable().when('nation', {
        is: 'ITA',
        then: Yup.string().required(messages["field_required"])
      }),
      province: Yup.string().when('nation', {
        is: 'ITA',
        then: Yup.string().required(messages["field_required"])
      }).nullable(),
      town: Yup.string().nullable().required(messages["field_required"]),
      address: Yup.string().nullable().required(messages["field_required"]),
      civic_number: Yup.string().nullable(),
      cap: Yup.string().nullable().required(messages["field_required"]),
    });
  }

  return Yup.object().shape({
    nation: Yup.string().nullable(),
    region: Yup.string().nullable(),
    province: Yup.string().nullable(),
    town: Yup.string().nullable(),
    address: Yup.string().nullable(),
    civic_number: Yup.string().nullable(),
    cap: Yup.string().nullable(),
  });
};
export const recapSchema = (messages: any) =>
  Yup.object().shape({
    email: Yup.string()
      .email(messages["email_not_valid"]).trim()
      .required(messages["field_required"])
      .test("email", messages['email_not_valid'], (val) =>
        emailValidator(val, true)
      ),
    phone: Yup.string()
      .test("phone", messages["invalid_phone_number"], (val) =>
        phoneValidator(val, true)
      )
      .nullable(),
  });
