import { push } from "connected-react-router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";
import Toast from "../../components/Toast";
import { FiscalCode } from "../../modules/@codice-fiscale";
import {
  setType,
  updateAddressData,
  updateExtraData,
  updatePersonalData,
} from "../../redux/Enroll/actions";
import { saveEnroll } from "../../redux/Enroll/middleware";
import { Enroll } from "../../redux/Enroll/types";
import { history } from "../../redux/store";
import { AppState } from "../../redux/types";
import GuardedRoute from "../../router/guards/GuardedRoute";
import { saveAttachmentsAsArray } from "../../services/documents";
import { Address, Completed, Extra, Personal } from "./views";

const Container = () => {
  const { path, url } = useRouteMatch();

  const { _id: organizationId } = useSelector((state: AppState) => state.Club);
  const { data, type } = useSelector((state: AppState) => state.Enroll);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setType("managerial_staff"));
  }, []);

  const onComplete = async (extra: Enroll["data"]["extra"]) => {
    dispatch(updateExtraData(extra));
    const files: any[] = extra.files as any;

    try {
      const { _id } = await saveEnroll({
        type,
        data: {
          ...data,
          extra,
        },
      });
      saveAttachmentsAsArray(_id, files, organizationId);
      dispatch(push(`${path}/thank-you`));
    } catch (err) {
      Toast.error("Errore");
      console.error(err);
    }
  };

  return (
    <div className="container">
      <Switch>
        <Route
          exact
          path={path}
          render={(props) => (
            <Personal
              {...props}
              data={data.personal}
              onSubmit={(values) => {
                dispatch(updatePersonalData(values));
                dispatch(push(`${path}/address`));

                if (FiscalCode.isItalian(values.fiscal_code!)) {
                  dispatch(updateExtraData({ nationality: "ITA" }));
                }
              }}
            />
          )}
        />

        <GuardedRoute
          exact
          condition={!!data.personal.fiscal_code}
          redirectTo={path}
          path={`${path}/address`}
          render={(props) => (
            <Address
              {...props}
              data={data.address}
              onBack={history.goBack}
              onSubmit={(values) => {
                dispatch(updateAddressData(values));
                dispatch(push(`${path}/extra`));
              }}
            />
          )}
        />

        <GuardedRoute
          exact
          condition={!!data.personal.fiscal_code}
          redirectTo={path}
          path={`${path}/extra`}
          render={(props) => (
            <Extra
              {...props}
              data={data.extra}
              onBack={history.goBack}
              onSubmit={(values) => {
                onComplete(values);
              }}
            />
          )}
        />

        <Route exact path={`${path}/thank-you`} component={Completed} />
      </Switch>
    </div>
  );
};

export default Container;
