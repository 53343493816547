import { trackEvent as conanTrackEvent } from "./service";
import { browserName, osName, browserVersion } from "react-device-detect";
import { uuid } from "short-uuid";

export class Conan {
  public static trackEvent = async (
    event_name: string,
    extra_properties?: any
  ) => {
    conanTrackEvent({
      event: event_name,
      distinct_id: 'enroll-approved-id',
      properties: {
        ...(await Conan.getClientInfo()),
        ...extra_properties,
      },
    })
      .then((_) => {
        console.log(`[conan] event ${event_name} tracked.`);
      })
      .catch((_) => {
        console.log(`[conan] failed to track ${event_name} event.`);
      });
  };

  public static getClientInfo = async (): Promise<TClientInfo> => {
    // const user_position = await getExactUserPosition();
    return {
      $os: osName,
      $browser: browserName,
      $current_url: window.location.href,
      $browser_version: browserVersion,
      $screen_height: window.screen.height as any as string,
      $screen_width: window.screen.width as any as string,
      // position: user_position,
    };
  };
}

type TUserLocation = { lat: number; lon: number };

type TClientInfo = {
  $os: string;
  $browser: string;
  $current_url: string;
  $browser_version: string;
  $screen_height: string;
  $screen_width: string;
  position?: TUserLocation;
};

const getExactUserPosition = (): Promise<TUserLocation | undefined> =>
  new Promise((resolve: any, reject: any) => {
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        (err: any) => {
          resolve(undefined);
        }
      );
    } catch (error) {
      resolve(undefined);
    }
  });
