import moment from "moment";
import { Conan } from "../../modules/@tracking";
import { PeopleService } from "../../services";
import { ClubState } from "../Club/reducer";
import { store } from "../store";
import { Enroll } from "./types";

export const saveEnroll = async (enroll: Enroll) => {
  const { legacy_id, name } = store.getState().Club as ClubState;

  Conan.trackEvent("Enroll created", {
    type: enroll.type,
    club: legacy_id,
    club_name: name,
  });

  return PeopleService.createEnroll(convertEnrollToPayload(enroll));
};

const convertEnrollToPayload = (enroll: Enroll): any => {
  const output = {
    ...enroll.data.personal,
    address: enroll.data.address,
    parent: enroll.data.parent.name ? enroll.data.parent : undefined,
    parent_2: enroll.data.parent_2.name ? enroll.data.parent_2 : undefined,
    entity_type: enroll.type,
    club: store.getState().Club.legacy_id,
    organizationId: store.getState().Club._id,
    groups: enroll.data.extra.groups,
    nationality: enroll.data.extra.nationality,
  };

  delete enroll.data.extra.files;
  delete enroll.data.extra.nationality;
  delete enroll.data.extra.groups;

  const metadata = normalizeMetadata(enroll.data.extra);

  return {
    ...output,
    metadata,
  };
};

const normalizeMetadata = (extra: Enroll["data"]["extra"]) => {
  extra = convertDatesToISOString(extra);

  const nowTimestamp = +new Date();

  const privacy: { [key: string]: { value: boolean; updatedAt: number } } = {};

  if (extra.privacy) {
    Object.keys(extra.privacy).forEach((key) => {
      privacy[key] = {
        value: !!extra.privacy?.[key],
        updatedAt: nowTimestamp,
      };
    });
  }

  return { ...extra, privacy };
};

const convertDatesToISOString = (extra: Enroll["data"]["extra"]) => {
  if (extra.medicalProfile?.medicalExaminationExpirationDate) {
    extra.medicalProfile.medicalExaminationExpirationDate =
      convertDateToISOString(
        extra.medicalProfile.medicalExaminationExpirationDate
      );
  }

  if (extra.identityDocument?.releaseDate) {
    extra.identityDocument.releaseDate = convertDateToISOString(
      extra.identityDocument?.releaseDate
    );
  }

  if (extra.identityDocument?.expirationDate) {
    extra.identityDocument.expirationDate = convertDateToISOString(
      extra.identityDocument?.expirationDate
    );
  }

  if (extra.identityDocument?.residencePermitExpirationDate) {
    extra.identityDocument.residencePermitExpirationDate =
      convertDateToISOString(
        extra.identityDocument?.residencePermitExpirationDate
      );
  }

  return extra;
};

const convertDateToISOString = (value: string) => {
  const momentObj = moment(value, "DD/MM/YYYY");

  if (momentObj.isValid()) {
    return momentObj.toISOString();
  }

  return undefined;
};
