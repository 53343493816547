import { Heading } from "@gle/base-ui.typography.heading";
import { Text } from "@gle/base-ui.typography.text";
import { Form, Formik, FormikContextType } from "formik";
import * as yup from "yup";
import { CheckBoxField } from "../Inputs/CheckBox";
import { DatePickerField } from "../Inputs/DatePicker";
import { InputField } from "../Inputs/Input";
import { SelectField } from "../Inputs/Select";
import { SelectFieldProps } from "../Inputs/Select/Field";
import { FieldLabel } from "../field-label";
import { FileUploader } from "../file-uploader";
import ImageUploader from "../image-uploader/image-uploader";
import { createValidationSchema } from "./create-validation-schema";
import { AttachmentConfig, FieldConfig, PrivacyConfig } from "./types";
import { useCustomSelect } from "./use-custom-select";

const CustomSelectField = (
  props: SelectFieldProps & { customSelectKey: string }
) => {
  const { options } = useCustomSelect(props.customSelectKey);

  return <SelectField {...props} options={options} />;
};

const Switcher = (props: {
  config: FieldConfig;
  formContext: FormikContextType<any>;
  touched?: boolean;
}) => {
  if (props.config.type === "SELECT") {
    return (
      <SelectField
        name={[props.config.key] as unknown as string}
        label={<FieldLabel {...props} />}
        options={props.config.options}
        isClearable
        selectKey={props.config.key}
        touched={props.touched}
      />
    );
  }
  if (props.config.type === "SELECT_MULTI_VALUES") {
    return (
      <SelectField
        name={[props.config.key] as unknown as string}
        label={<FieldLabel {...props} />}
        options={props.config.options}
        isClearable
        selectKey={props.config.key}
        touched={props.touched}
        isMulti={true}
      />
    );
  }

  if (props.config.type === "CUSTOM_SELECT" && props.config.customSelectKey) {
    return (
      <CustomSelectField
        name={[props.config.key] as unknown as string}
        label={<FieldLabel {...props} />}
        options={props.config.options}
        isClearable
        touched={props.touched}
        customSelectKey={props.config.customSelectKey}
      />
    );
  }

  if (props.config.type === "TEXT") {
    return (
      <InputField
        name={[props.config.key] as unknown as string}
        type="text"
        label={<FieldLabel {...props} />}
        touched={props.touched}
      />
    );
  }

  if (props.config.type === "NUMBER") {
    return (
      <InputField
        name={[props.config.key] as unknown as string}
        type="number"
        label={<FieldLabel {...props} />}
        touched={props.touched}
      />
    );
  }

  if (props.config.type === "CHECKBOX") {
    return (
      <CheckBoxField
        name={[props.config.key] as unknown as string}
        label={<FieldLabel {...props} />}
        touched={props.touched}
      />
    );
  }

  if (props.config.type === "TEXTAREA") {
    return (
      <InputField
        name={[props.config.key] as unknown as string}
        type="textarea"
        label={<FieldLabel {...props} />}
        touched={props.touched}
      />
    );
  }

  if (props.config.type === "DATE") {
    return (
      <DatePickerField
        name={[props.config.key]}
        isClearable
        label={<FieldLabel {...props} />}
        touched={props.touched}
      />
    );
  }

  if (props.config.type === "IMAGE") {
    return (
      <ImageUploader
        name={[props.config.key]}
        label={<FieldLabel {...props} />}
        value={props.formContext.values[props.config.key]}
        onChange={(url) =>
          props.formContext.setFieldValue(
            [props.config.key] as unknown as string,
            url
          )
        }
      />
    );
  }

  return <></>;
};

const Attachments = (props: {
  config: AttachmentConfig;
  formContext: FormikContextType<any>;
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Text
        color={
          props.formContext.errors[props.config.key] &&
          props.formContext.submitCount > 0
            ? "danger"
            : undefined
        }
      >
        <FieldLabel config={props.config} />
      </Text>

      <FileUploader
        allowDelete
        value={
          props.formContext.values
            ? props.formContext.values[props.config.key]?.url
            : undefined
        }
        onValueChanged={(value) => {
          if (value) {
            props.formContext.setFieldValue(
              [props.config.key] as unknown as string,
              {
                url: value.url,
                name: props.config.label,
                mime: value.mimeType,
              }
            );
          } else {
            props.formContext.setFieldValue(
              [props.config.key] as unknown as string,
              undefined
            );
          }
        }}
        mimeTypes={"application/pdf,text/csv,image/*,text/plain"}
        api={{
          baseURL: `${window._env_.REACT_APP_API_URL_PEPA_SERVICE}files`,
        }}
      />
    </div>
  );
};

export type DynamicFormProps = {
  formRef: React.Ref<any>;
  fields?: FieldConfig[];
  privacy?: PrivacyConfig[];
  attachments?: AttachmentConfig[];
  data: any;
  onSubmit: (values: any) => void;
  submitText?: string;
  isSaving?: boolean;
  touched?: boolean;
};

const recomposeDottedKeys = (input: { [key: string]: any }) => {
  const output: { [key: string]: any } = {};

  for (let key in input) {
    const value = input[key];
    const keys = key.split(".");
    let currentObj = output;

    for (let i = 0; i < keys.length; i++) {
      const currentKey = keys[i];

      if (i === keys.length - 1) {
        currentObj[currentKey] = value;
      } else {
        if (!currentObj.hasOwnProperty(currentKey)) {
          currentObj[currentKey] = {};
        }
        currentObj = currentObj[currentKey];
      }
    }
  }

  return output;
};

export const DynamicForm = (props: DynamicFormProps) => {
  const fields = [
    ...(props.fields || []),
    ...(props.privacy || []).map((t) => ({
      key: `privacy.${t.key}`,
      type: "CHECKBOX",
      required: t.required,
    })),
    ...(props.attachments || []).map((attachment) => ({
      key: `files.${attachment.key}`,
      type: "FILE",
      required: attachment.required,
    })),
  ];

  const validationSchema = yup
    .object()
    // @ts-ignore
    .shape(fields.reduce(createValidationSchema, {}));

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnMount
      initialValues={props.data}
      onSubmit={(val) => {
        props.onSubmit(recomposeDottedKeys(val));
      }}
      innerRef={props.formRef}
    >
      {(formContext) => {
        return (
          <Form>
            {props.fields?.length ? (
              <div
                style={{ display: "flex", flexDirection: "column", gap: 20 }}
              >
                {props.fields?.map((c) => (
                  <Switcher
                    config={c}
                    formContext={formContext}
                    touched={props.touched}
                  />
                ))}
              </div>
            ) : (
              <></>
            )}

            {props.attachments?.length ? (
              <div
                style={{
                  marginTop: 20,
                  paddingTop: 20,
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                  borderTop: "1px solid #eee",
                }}
              >
                <Heading level={4}>Documenti e moduli</Heading>
                {props.attachments.map((c) => (
                  <Attachments
                    config={{ ...c, key: `files.${c.key}` }}
                    formContext={formContext}
                  />
                ))}
              </div>
            ) : (
              <></>
            )}

            {props.privacy?.length ? (
              <div
                style={{
                  marginTop: 20,
                  paddingTop: 20,
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                  borderTop: "1px solid #eee",
                }}
              >
                <Heading level={4}>Consensi</Heading>
                {props.privacy.map((c) => (
                  <div>
                    <CheckBoxField
                      name={[`privacy.${c.key}`] as unknown as string}
                      touched={props.touched}
                      extraContent={
                        <div style={{ paddingLeft: 40 }}>
                          {c.content && (
                            <Text size="s" color="gray">
                              {c.content}
                            </Text>
                          )}
                        </div>
                      }
                      label={
                        <FieldLabel
                          config={{
                            label: (
                              <span style={{ color: "black" }}>
                                Dichiaro di aver preso visione e di accettare{" "}
                                {c.url ? (
                                  <a
                                    href={c.url}
                                    target="_blank"
                                    style={{ color: "var(--accent-color)" }}
                                  >
                                    {c.label}
                                  </a>
                                ) : (
                                  <strong>{c.label}</strong>
                                )}
                              </span>
                            ),
                            required: c.required,
                          }}
                        />
                      }
                    />
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
