import { Text } from "@gle/base-ui.typography.text";
import { push } from "connected-react-router";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router";
import { Role } from "./container";

export type RoleCardProps = {
  role: Role;
};

export const RoleCard = (props: RoleCardProps) => {
  const dispatch = useDispatch();

  const [isHover, setIsHover] = useState<boolean>(false);
  const { path } = useRouteMatch();

  const choiceEnroll = () => {
    if (path.endsWith("/")) {
      dispatch(push(`${path}${props.role.path}`));
    } else {
      dispatch(push(`${path}/${props.role.path}`));
    }
  };

  return (
    <div
      style={{
        borderRadius: 8,
        padding: 20,
        display: "flex",
        alignItems: "end",
        borderWidth: 1,
        borderStyle: "solid",
        gap: 20,
        cursor: "pointer",
        borderColor: "#EEEEEE",
        marginBottom: 10,
        backgroundColor: isHover ? "#F5F5F5" : "",
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => choiceEnroll()}
    >
      <div style={{}}>{props.role.avatar}</div>
      <div style={{}}>
        <Text weight="medium">{props.role.name}</Text>
        <Text color="gray-light" weight="light">
          {props.role.description}
        </Text>
      </div>
    </div>
  );
};
