import { Button } from "@gle/base-ui.buttons.button";
import { Heading } from "@gle/base-ui.typography.heading";
import { Text } from "@gle/base-ui.typography.text";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ViewProps } from ".";
import { DynamicForm } from "../../../components/dynamic-form";
import { Enroll } from "../../../redux/Enroll/types";
import { AppState } from "../../../redux/types";

export const Extra = (props: ViewProps<Enroll["data"]["extra"]>) => {
  const { messages } = useSelector((state: AppState) => state.Locale);

  const { enrollSettings } = useSelector((state: AppState) => state.Club);

  const [touched, setTouched] = useState<boolean>(false);

  const formRef: any = useRef();

  return (
    <>
      <Heading level={3} className="mb-20">
        Completa il profilo
        <Text
          weight={"light"}
          color="gray-light"
          style={{ display: "inline-block", marginLeft: 5 }}
        ></Text>
      </Heading>

      {!enrollSettings.enroll.formConfig?.attachments?.length &&
        !enrollSettings.enroll.formConfig?.fields?.length &&
        !enrollSettings.enroll.formConfig?.privacy?.length && (
          <Text>
            Hai inserito tutte le informazioni richieste. Clicca su Avanti per
            concludere l'iscrizione.
          </Text>
        )}

      <DynamicForm
        formRef={formRef}
        data={{}}
        onSubmit={(values) => {
          props.onSubmit?.(values);
        }}
        touched={touched}
        attachments={enrollSettings.enroll.formConfig?.attachments}
        fields={enrollSettings.enroll.formConfig?.fields}
        privacy={enrollSettings.enroll.formConfig?.privacy}
      />

      <div className="flex gap-20" style={{ marginTop: 40 }}>
        <Button
          level="tertiary"
          color="accent"
          onClick={props.onBack}
          style={{ flex: "0.2" }}
        >
          {messages["back"]}
        </Button>
        <Button
          type="submit"
          onClick={() => {
            setTouched(true);
            formRef.current.submitForm();
          }}
          style={{ flex: "0.8" }}
        >
          {messages["next"]}
        </Button>
      </div>
    </>
  );
};
