import { EPath } from './types/path.enum';
import { EScopes } from './types/scopes.enum';

const routes = [
	{
		path: EPath.athlete,
		scope: EScopes.athlete,
	},
	{
		path: EPath.technical_staff,
		scope: EScopes.technical_staff,
	},
	{
		path: EPath.managerial_staff,
		scope: EScopes.managerial_staff,
	},
];

export default routes;
