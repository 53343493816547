import { push } from "connected-react-router";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";
import Toast from "../../components/Toast";
import { FiscalCode } from "../../modules/@codice-fiscale";
import {
  resetData,
  setType,
  updateAddressData,
  updateExtraData,
  updateParentsData,
  updatePersonalData,
} from "../../redux/Enroll/actions";
import { saveEnroll } from "../../redux/Enroll/middleware";
import { Enroll } from "../../redux/Enroll/types";
import { history } from "../../redux/store";
import { AppState } from "../../redux/types";
import GuardedRoute from "../../router/guards/GuardedRoute";
import { saveAttachments } from "../../services/documents";
import { Address, Completed, Extra, Parents, Personal } from "./views";

const Container = () => {
  const { path, url } = useRouteMatch();

  const { _id: organizationId } = useSelector((state: AppState) => state.Club);
  const { data, type } = useSelector((state: AppState) => state.Enroll);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setType("athlete"));
  }, []);

  const isUnderage = (values: Enroll["data"]["personal"]) => {
    if (!values?.birthdate) return false;

    const age = moment().diff(moment(values.birthdate, "DD/MM/YYYY"), "years");
    return age < 18;
  };

  const onComplete = async (extra: Enroll["data"]["extra"]) => {
    dispatch(updateExtraData(extra));

    const files = extra.files;

    try {
      const { _id } = await saveEnroll({
        type,
        data: {
          ...data,
          extra,
        },
      });

      saveAttachments(_id, files, organizationId);

      dispatch(push(`${path}/thank-you`));
      dispatch(resetData());
    } catch (err) {
      Toast.error("Errore");
      console.error(err);
    }
  };

  return (
    <div className="container">
      <Switch>
        <Route
          exact
          path={path}
          render={(props) => (
            <Personal
              {...props}
              data={data.personal}
              onSubmit={(values) => {
                dispatch(updatePersonalData(values));
                if (isUnderage(values)) {
                  dispatch(push(`${path}/parents`));
                } else {
                  dispatch(push(`${path}/address`));
                }

                if (FiscalCode.isItalian(values.fiscal_code!)) {
                  dispatch(updateExtraData({ nationality: "ITA" }));
                }
              }}
            />
          )}
        />

        <GuardedRoute
          exact
          path={`${path}/parents`}
          condition={isUnderage(data.personal)}
          redirectTo={path}
          render={(props) => (
            <Parents
              {...props}
              data={{
                parent: { ...data.parent, recap: { email: "" } },
                parent_2: { ...data.parent_2, recap: { email: "" } },
              }}
              onBack={history.goBack}
              onSubmit={(values) => {
                dispatch(updateParentsData(values));
                dispatch(push(`${path}/address`));
              }}
            />
          )}
        />

        <GuardedRoute
          exact
          condition={!!data.personal.fiscal_code}
          redirectTo={path}
          path={`${path}/address`}
          render={(props) => (
            <Address
              {...props}
              data={data.address}
              parent={data.parent}
              onBack={history.goBack}
              onSubmit={(values) => {
                dispatch(updateAddressData(values));
                dispatch(push(`${path}/extra`));
              }}
            />
          )}
        />

        <GuardedRoute
          exact
          condition={!!data.personal.fiscal_code}
          redirectTo={path}
          path={`${path}/extra`}
          render={(props) => (
            <Extra
              {...props}
              data={data.extra}
              onBack={history.goBack}
              onSubmit={(values) => {
                onComplete(values);
              }}
            />
          )}
        />

        <Route exact path={`${path}/thank-you`} component={Completed} />
      </Switch>
    </div>
  );
};

export default Container;
