import { Country, Province, Region } from "@golee/gle-locations/lib/types";

export type LocationState = {
  regions: { [key: string]: Region };
  provinces: { [key: string]: Province };
  countries: { [key: string]: Country };
};

export enum ACTIONS {
  LOAD = "LOAD_LOCATIONS",
}

export type Load = {
  payload: LocationState;
  type: ACTIONS.LOAD;
};

export type Actions = Load;
