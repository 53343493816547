import { all, fork } from "redux-saga/effects";
import { ACTIONS } from "./types";
import { store } from "../store";
import { locations } from "@golee/gle-locations";

const load = async () => {
  store.dispatch({
    type: ACTIONS.LOAD,
    payload: {
      ...(await locations("it")),
    },
  });
};

export default function* rootSaga() {
  yield all([fork(load)]);
}
