import styled from "styled-components";
import InputMask from "react-input-mask";

const Wrapper = styled.div`
  width: 100%;

  label {
    font-size: var(--s-size);
    display: block;
    color: var(--gray-light-color);
    margin-bottom: 2px;
  }

  input {
    box-sizing: border-box;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    padding: 0 7px;
    border: 1px solid transparent;
    border-radius: 10px;
    appearance: none;
    outline: none;
    background: transparent;
    height: 48px;
    font-size: var(--m-size);
    font-family: var(--font-family);
    background-color: #f3f3f4;

    &:focus,
    &:active,
    &:hover {
      border-color: var(--gray-lightest-color);
      background-color: #fff;
    }
  }
`;

const ClearIcon = styled.svg`
  width: 20px;
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  stroke: #ccc;

  &:hover {
    stroke: #777;
  }
`;

export type DatePickerProps = {
  /**
   * Name of the field (same of the form value).
   */
  name: string;

  /**
   * Placeholder of the field.
   */
  placeholder?: string;

  /**
   * Label of the field.
   */
  label: string | React.ReactNode;

  /**
   * Format of the date to be returned as value.
   */
  outputFormat?: string;

  /**
   * Defines if the field can be clearable. Shows an 'x' button.
   */
  isClearable?: boolean;

  /**
   * Value of the field
   */
  value?: string;

  /**
   * On change trigger.
   */
  onChange: (value: string) => void;
};

const DatePickerField = ({
  outputFormat = "DD/MM/YYYY",
  placeholder = "DD/MM/YYYY",
  ...props
}: DatePickerProps) => {
  return (
    <Wrapper>
      {props.label && <label htmlFor={props.name}>{props.label}</label>}

      <div style={{ position: "relative" }}>
        <InputMask
          {...props}
          inputMode="numeric"
          placeholder={placeholder}
          onChange={(ev) => {
            if (ev.target.value !== "__/__/____") {
              props.onChange(ev.target.value);
            }
          }}
          mask="99/99/9999"
        />

        {props.isClearable && props.value && (
          <ClearIcon
            onClick={() => {
              props.onChange("");
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </ClearIcon>
        )}
      </div>
    </Wrapper>
  );
};

export default DatePickerField;
