import { ErrorMessage, FieldConfig, useField } from "formik";
import styled from "styled-components";
import Component, { InputPhoneProps } from "./Component";

type Props = FieldConfig<any> & Pick<InputPhoneProps, "label">;

const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  column-gap: 3px;
  row-gap: 3px;
  gap: 3px;

  .form-field-error {
    color: var(--danger-color);
    font-size: var(--xs-size);
  }

  &.error {
    label {
      color: var(--danger-color);
    }
    input {
      border-color: var(--danger-color);
    }
  }
`;

const Field = ({ ...props }: Props) => {
  const [field, meta, helpers] = useField(props);

  return (
    <Wrapper className={`${meta.touched && meta.error ? "error" : ""}`}>
      <Component {...props} {...field} onChange={helpers.setValue} />
      <ErrorMessage
        name={props.name}
        component="div"
        className="form-field-error"
      />
    </Wrapper>
  );
};

export default Field;
