import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/types";

export const useSelectOptions = (key?: string) => {
  const { groups } = useSelector((state: AppState) => state.Club);
  const [options, setOptions] = useState<{ label: string; value: string }[]>();

  const loadOptions = (key?: string) => {
    if (key === "groups" && groups) {
      setOptions(
        groups.map((group) => ({
          value: group.groupId,
          label: group.name,
        }))
      );
    }
  };

  useEffect(() => {
    loadOptions(key);
  }, [key]);

  return { options };
};
