import { useSelector } from "react-redux";
import { AppState } from "../../../redux/types";
import { Heading } from "@gle/base-ui.typography.heading";
import { Text } from "@gle/base-ui.typography.text";
import { LinkSharer } from "../../../components/LinkSharer";
import { CheckIcon } from "../../../components/CheckIcon";
import { Conan } from "../../../modules/@tracking";

export const Completed = () => {
  const club = useSelector((state: AppState) => state.Club);
  const { sharableUrl } = useSelector((state: AppState) => state.Club);

  return (
    <>
      <Heading level={3} className="mb-20">
        Congratulazioni!
      </Heading>

      <Text className="mb-20">
        I tuoi dati sono stati inviati correttamente alla società. Grazie per
        essere entrato a far parte della rivoluzione digitale del mondo
        sportivo.
      </Text>

      <CheckIcon />

      <Heading level={4} className="mt-30 mb-20">
        Invita gli atleti della tua squadra!
      </Heading>
      <Text>
        Copiando il seguente link potrai far iscrivere i tuoi atleti
        direttamente a Golee!
      </Text>

      <LinkSharer
        url={sharableUrl!}
        onShare={() => {
          Conan.trackEvent("Enroll link shared", {
            club: club._id,
            club_name: club.name,
            from: "technical_staff",
          });
        }}
      />
    </>
  );
};
