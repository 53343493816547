import { ErrorMessage, FieldConfig, useField } from "formik";
import { ReactNode, useEffect } from "react";
import styled from "styled-components";
import Component, { CheckBoxProps } from "./Component";

type Props = FieldConfig<any> & { touched?: boolean } & Pick<
    CheckBoxProps,
    "render" | "label"
  > & { extraContent?: ReactNode };

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 3px;
  row-gap: 3px;
  gap: 3px;

  &.h-auto {
    height: auto;
  }

  .form-field-error {
    color: var(--danger-color);
    font-size: var(--xs-size);
  }
`;

const Field = ({ ...props }: Props) => {
  const [field, meta, helpers] = useField<boolean>(props);

  useEffect(() => {
    helpers.setTouched(true, true);
  }, [props.touched]);

  return (
    <Wrapper className={`${meta.touched && meta.error ? "error" : ""} `}>
      <Component {...props} {...field} onChange={helpers.setValue} />

      {props.extraContent}

      <ErrorMessage
        name={props.name}
        component="div"
        className="form-field-error"
      />
    </Wrapper>
  );
};

export default Field;
