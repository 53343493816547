import { ErrorMessage, FieldAttributes, useField } from "formik";
import { useEffect } from "react";
import styled from "styled-components";
import Component, { DatePickerProps } from "./Component";

type Props = FieldAttributes<any> & { touched?: boolean } & Pick<
    DatePickerProps,
    "isClearable" | "outputFormat" | "placeholder"
  >;

const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .form-field-error {
    color: var(--danger-color);
    font-size: var(--xs-size);
  }

  &.error {
    label {
      color: var(--danger-color);
    }
    input {
      border-color: var(--danger-color);
    }
  }
`;

const Field = ({ ...props }: Props) => {
  const [field, meta, helpers] = useField(props);

  useEffect(() => {
    helpers.setTouched(true, true);
  }, [props.touched]);

  return (
    <Wrapper className={`${meta.touched && meta.error ? "error" : ""} `}>
      <Component
        {...field}
        {...props}
        onChange={helpers.setValue}
        onBlur={field.onBlur}
      />
      <ErrorMessage
        name={props.name}
        component="div"
        className="form-field-error"
      />
    </Wrapper>
  );
};

export default Field;
