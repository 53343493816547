import { isValidPhoneNumber } from "react-phone-number-input";
import InputPhone from "./Component";
import InputPhoneField from "./Field";

export { InputPhone, InputPhoneField };

export const phoneValidator = (value?: string, nullable?: boolean): boolean => {
  if (nullable) {
    if (!value) {
      return true;
    }
  }

  if (!value) {
    return false;
  }

  return isValidPhoneNumber(value);
};
