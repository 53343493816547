import CodiceFiscale from "codice-fiscale-js";
import _ from "lodash";
import moment from "moment";

export class FiscalCode {
  static getBirthDate(value: string) {
    let { month, year, day } = new CodiceFiscale(value).toJSON();
    return moment({ month: month - 1, day, year }).format("DD/MM/YYYY");
  }

  static getBirthplace(value: string) {
    let { birthplace } = new CodiceFiscale(value).toJSON();
    return _.capitalize(birthplace);
  }

  static isItalian(value: string) {
    let { birthplaceProvincia } = new CodiceFiscale(value).toJSON();
    return birthplaceProvincia !== 'EE';
  }

  static getGender(value: string) {
    let { gender } = new CodiceFiscale(value).toJSON();
    return gender;
  }

  static reverse(value: string): {
    sex: string;
    birthplace: string;
    birthdate: string;
  } {
    return {
      sex: FiscalCode.getGender(value),
      birthplace: FiscalCode.getBirthplace(value),
      birthdate: FiscalCode.getBirthDate(value),
    };
  }

  static isValid(value?: string) {
    return CodiceFiscale.check(value || "");
  }
}
