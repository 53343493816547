import { ErrorMessage, FieldConfig, useField } from "formik";
import { useEffect } from "react";
import styled from "styled-components";
import { useSelectOptions } from "../../dynamic-form/use-select";
import Component, { SelectProps } from "./Component";

export type SelectFieldProps = FieldConfig<any> & { touched?: boolean } & Pick<
    SelectProps,
    "label" | "isClearable" | "options" | "onChange"
  >;

const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  column-gap: 3px;
  row-gap: 3px;
  gap: 3px;

  label {
    font-size: var(--s-size);
    display: block;
    color: var(--gray-light-color);
  }

  .form-field-error {
    color: var(--danger-color);
    font-size: var(--xs-size);
  }

  &.error {
    label {
      color: var(--danger-color);
    }

    .select__control {
      border-color: var(--danger-color);
    }
  }
`;

const Field = ({
  ...props
}: SelectFieldProps & { selectKey?: string; isMulti?: boolean }) => {
  const [field, meta, helpers] = useField(props);
  const { options } = useSelectOptions(props.selectKey);

  useEffect(() => {
    helpers.setTouched(true, true);
  }, [props.touched]);

  return (
    <Wrapper className={`${meta.touched && meta.error ? "error" : ""}`}>
      <Component
        {...props}
        {...field}
        options={options || props.options}
        onChange={(option) => {
          props.onChange?.(option);
        }}
        key={props.selectKey}
        isMulti={props.isMulti}
      />

      <ErrorMessage
        name={props.name}
        component="div"
        className="form-field-error"
      />
    </Wrapper>
  );
};

export default Field;
