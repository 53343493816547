import { LocationState, ACTIONS, Actions } from "./types";

const initialState: LocationState = {
  regions: {},
  provinces: {},
  countries: {},
};

export default function reducer(
  state = initialState,
  action: Actions
): LocationState {
  switch (action.type) {
    case ACTIONS.LOAD: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}
